import * as React from "react"
import Svg, { Path } from "react-native-svg"
import { palette } from "../../styles/utils";

function Place(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 487.651 487.651"
      height="26px"
      width="26px"
      {...props}
    >
      <Path fill={palette.blue} d="M243.809 0C113.297 0 73.406 101.628 73.406 186.719c0 123.263 137.775 300.933 170.403 300.933 34.667 0 170.437-179.023 170.437-300.933C414.246 101.628 374.322 0 243.809 0zm.033 244.185c-42.14 0-76.281-34.142-76.281-76.265 0-42.139 34.142-76.28 76.281-76.28 42.106 0 76.248 34.142 76.248 76.28 0 42.123-34.141 76.265-76.248 76.265z" />
    </Svg>
  )
}

export default Place;
