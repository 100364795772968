import React from 'react';
import { getDateString, getMoneyString } from '../../services/time';
import { View, Text } from 'react-native';

const BalanceCard = (props) => {
const balanceItem = props.balanceItem;

    return (
        <View key={balanceItem} style={{padding: 14, borderBottomWidth: 0.5, borderBottomLeftRadius: 4,borderBottomRightRadius: 4, marginHorizontal:2, backgroundColor:'#fff'}}>
        <View style={{flexDirection:'row', justifyContent:'space-between'}}>
           <Text style={{color:'gray', fontSize: 12}}>
                {getDateString(balanceItem.timestamp)}
            </Text>
            <Text style={{fontWeight:'bold', color:(balanceItem.sum < 0 ? 'red' : 'green')}}>
              {getMoneyString(balanceItem.sum) + ' руб.'}
              </Text>                    
          </View>
          <Text style={{fontSize:12, paddingVertical: 8}}>{balanceItem.description}</Text>
            {balanceItem.special_number && 
                    <Text style={{fontSize:12, paddingVertical: 8,textAlign:'center'}}>{balanceItem.special_number}</Text>
                }
      </View>
    )
}

export default BalanceCard;