import React, { useEffect, useState } from 'react';
import { ScrollView } from 'react-native';
import { View, Text, Modal, StyleSheet, TouchableOpacity, ActivityIndicator } from 'react-native';
import { surcharge } from '../../services/network';
import { getMoneyString, getOnlyDateString } from '../../services/time';

import { palette, utils } from '../../styles/utils';
const IncomeScreen = props => {
    const { visible, onShow } = props;
    const [indicator, showIndicator] = useState(true);
    const [makingsList, setMakingsList] = useState([]);
 useEffect(() => {
    setTimeout(() => {
        surcharge(new Date() / 1000 - (870000 * 3), new Date() / 1000).then((result) => {
            setMakingsList(result);
        })
        showIndicator(false)
    }, 3000);
},[])

    return (
          <View style={styles.centeredView} >
            <Modal 
                animationType='slide'
                transparent={true}
                visible={visible}>
 <View  style={styles.modalView} >
      <View style={{flexDirection:'row', justifyContent:'space-between', paddingVertical: utils.padding}}>
                <Text style={{fontSize:16, fontWeight:'bold'}}>{'Заработки за последние 30 дней'}</Text>
                                    <TouchableOpacity onPress={() => onShow(false)}>
                                        <Text style={{fontSize:16, fontWeight:'bold'}}>
                                                {'Закрыть'}
                                            </Text>
                                        </TouchableOpacity>
                                </View>
        <ScrollView showsVerticalScrollIndicator={false}>
            {indicator && 
                     <ActivityIndicator size={'large'} color={'#fdd107'} style={{paddingVertical: utils.padding * 2}}/> 
                     }
                     {makingsList && makingsList.map((item, index) => {
                         return (
                            <View key={index} style={{
                                flexDirection: 'row', justifyContent: 'space-between', borderBottomWidth: 0.5, borderBottomColor: 'gray',
                                marginHorizontal: utils.padding, paddingVertical: utils.padding / 1.4
                            }}>
                                <View style={{ flexDirection: 'column' }}>
                                    <Text style={{ fontSize: utils.fontSizeNormal * 1.2, lineHeight: 24, color: 'black' }}>{getOnlyDateString(item.date)}</Text>
                                    <Text style={{ fontSize: utils.fontSizeNormal, color: 'black', lineHeight: 24 }}>{item.driver_schedule.toString().replace('График отсутствует', 'ВХ').replace('НР', 'ВХ').replace('(_', '(')}</Text>
                                </View>
                                <View style={{ flexDirection: 'column' }}>
                                    <Text style={{ fontWeight: '700', textAlign: 'right', lineHeight: 24, fontSize: utils.fontSizeNormal * 1.1, color: 'black' }}>{getMoneyString((item.surcharge == null ? item.order_income : item.hours_online_income)) + ' руб.'}</Text>
                                </View>
                            </View>
    
                         )
                     })}
                     {makingsList.length === 0 && !indicator &&
                         <View style={{ padding: utils.padding * 2 }}>
                         <Text style={{ textAlign: 'center', fontSize: utils.fontSizeNormal * 1.2, color: 'black' }}>
                             {'В указанный период не было рабочих смен'}</Text>
                     </View>
}
        </ScrollView>
        </View>
        </Modal>
      </View>
    );
  }

  const styles = StyleSheet.create({
    centeredView: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      marginTop: 22,
      backgroundColor:palette.white,
    },
    modalView: {
      marginTop:12,
      marginBottom: -10,
      backgroundColor: "white",
      borderRadius: 20,
      padding: 12,
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2
      },
      shadowOpacity: 5,
      shadowRadius: 14,
      elevation: 5,
      width:'100%',
      height:'100%'
    },
    button: {
      borderRadius: 20,
      padding: 10,
      elevation: 2
    },
    buttonOpen: {
      backgroundColor: "#F194FF",
    },
    buttonClose: {
      backgroundColor: "#2196F3",
    },
    textStyle: {
      color: "white",
      fontWeight: "bold",
      textAlign: "center"
    },
    modalText: {
      marginBottom: 15,
      textAlign: "center"
    }
  });

  export default IncomeScreen;