import { PixelRatio } from 'react-native';
import { generateStyleSheet } from "./styles";
import { storeData } from '../screens/loginScreen'
import DeviceInfo from "react-native-device-info";
export const utils = {};

utils.font = 'Roboto-Regular';
utils.fontBig = 'Roboto-Bold';

let resolutionCorrection = 1;

export let ratioList = [
    { id: 0.7, name: 'x0.7' },
    { id: 0.8, name: 'x0.8' },
    { id: 0.9, name: 'x0.9' },
    { id: 1.0, name: 'x1.0' },
    { id: 1.1, name: 'x1.1' },
    { id: 1.2, name: 'x1.2' },
    { id: 1.3, name: 'x1.3' },
    { id: 1.4, name: 'x1.4' },
    { id: 1.5, name: 'x1.5' },
]

export let ratioListSmall = [
    { id: 0.7, name: 'x0.7' },
    { id: 0.8, name: 'x0.8' },
    { id: 0.9, name: 'x0.9' },
    { id: 1.0, name: 'x1.0' },
    { id: 1.1, name: 'x1.1' },
    { id: 1.2, name: 'x1.2' },
]

export let getRatio = function () {
    return resolutionCorrection;
}

export let setRatio = function (ratio) {
    console.log(ratio)
    resolutionCorrection = Number(ratio);
    storeData('ratio', getRatio().toString().substring(0, 3)).then()
    updateResolution();
    generateStyleSheet();
}

export let reduceRatio = function () {
    if (getRatio() < 0.8)
        return;

    resolutionCorrection = Number((resolutionCorrection - 0.1).toFixed(2));
    console.log(resolutionCorrection)
    storeData('ratio', getRatio().toString().substring(0, 3)).then()
    updateResolution();
    generateStyleSheet();
}

export let increaseRatio = function () {
    if (getRatio() >= 1.5 || (getRatio() >= 1.2 && !DeviceInfo.isTablet()))
        return;

    resolutionCorrection = Number((resolutionCorrection + 0.1).toFixed(2));
    console.log(resolutionCorrection)
    storeData('ratio', getRatio().toString().substring(0, 3)).then()
    updateResolution();
    generateStyleSheet();
};

let updateResolution = function () {

    let pixelSizeForLayoutSize = PixelRatio.getPixelSizeForLayoutSize(1)

    pixelSizeForLayoutSize = 2;

    utils.fontSizeSmall = pixelSizeForLayoutSize * 5.5 * resolutionCorrection;
    utils.fontSizeNormal = pixelSizeForLayoutSize * 6.5 * resolutionCorrection;
    utils.fontSizeBig = pixelSizeForLayoutSize * 8.5 * resolutionCorrection;
    utils.fontSizeGiant = pixelSizeForLayoutSize * 12 * resolutionCorrection;

    utils.iconSizeSmall = pixelSizeForLayoutSize * 8 * resolutionCorrection;
    utils.iconSize = pixelSizeForLayoutSize * 12.5 * resolutionCorrection;
    utils.iconSizeBig = pixelSizeForLayoutSize * 14.5 * resolutionCorrection;


    utils.padding = 12 * resolutionCorrection;
    // utils.padding = PixelRatio.getPixelSizeForLayoutSize(10);

    utils.height = 40 * resolutionCorrection;

    utils.borderRadius = 3 * resolutionCorrection;
}

updateResolution();

export let palette = {
    white: '#ffffff',
    black: '#000000',

    gray: '#7b7b7b',
    darkgray: '#383838',
    lightgray: '#ebebeb',

    yellow: '#fdd107',
    lightyellow: '#ffeaa7',
    darkyellow:'#e3b900',
    red: '#c4000a',
    green: '#2faf01',
    lightgreen: '#95f8ce',
    blue: '#1c4fc4',
};

utils.colors = {
    background: palette.lightgray,
    // background: palette.lightgreen,
    backNewFeed: palette.yellow,
    rowBackground: palette.white,

    buttonBackground: palette.black,
    buttonBackgroundDisabled: palette.gray,
    buttonText: palette.white,

    smallText: palette.gray,
    // normalText: '#ff0a29',
    normalText: palette.black,
    // bigText: '#19a915',
    bigText: palette.black,

    menuColor: palette.darkgray,
    menuIcon: palette.gray,
    menuIconActive: palette.yellow,

    profileIcon: palette.darkgray,
    profileIconActive: palette.white,

    header: palette.yellow,
    backColor: palette.yellow,
    tab: palette.yellow,
    tabBorder: palette.black,

    shadow: palette.black,

    inputBorder: palette.gray,

    calendarArrows: palette.black,

    good: palette.green,
    important: palette.red,
    importantRow: palette.lightyellow,
    link: palette.blue,
};

export const monthNames = ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря'];

//TODO
let timeZoneOffset = -180 - new Date().getTimezoneOffset()

// 8 Мая в 12:00
export function getDateString(time) {

    if (!time || time === 0)
        return '';

    let timeObject = new Date(time * 1000 - timeZoneOffset * 60 * 1000);
    // timeObject.toLocaleString("ru-RU", {timeZone: "Europe Moscow"})

    return timeObject.getDate() + ' ' + monthNames[timeObject.getMonth()] + ' в ' + timeObject.getHours() + ':' +
        (timeObject.getMinutes().toString().length === 1 ? '0' + timeObject.getMinutes() : timeObject.getMinutes());
}

// 15:57
export function getOnlyTimeString(time) {

    if (time === 0)
        return 'Время';

    let timeObject = new Date(time * 1000 - timeZoneOffset * 60 * 1000);
    // timeObject.toLocaleString("ru-RU", {timeZone: "Europe Moscow"})

    return timeObject.getHours() + ':' +
        (timeObject.getMinutes().toString().length === 1 ? '0' + timeObject.getMinutes() : timeObject.getMinutes());
}

// 9 Мая
export function getOnlyDateString(time) {

    if (time === 0)
        return 'Время';

    let timeObject = new Date(time * 1000 - timeZoneOffset * 60 * 1000);
    // timeObject.toLocaleString("ru-RU", {timeZone: "Europe Moscow"})

    return timeObject.getDate() + ' ' + monthNames[timeObject.getMonth()];
}

export function getTimerString(sec) {

    // console.log(sec)
    // console.log(Math.floor(getDate().getDate()/1000 - sec))

    if (!sec || sec < 0)
        sec = 0;

    sec = Math.floor(sec)

    let hours = Math.floor(sec / (60 * 60));
    let minutes = Math.floor(sec / 60) % 60;
    let seconds = Math.floor(sec % 60);

    hours = (hours.toString().length === 1 ? '0' + hours : hours);
    minutes = (minutes.toString().length === 1 ? '0' + minutes : minutes);
    seconds = (seconds.toString().length === 1 ? '0' + seconds : seconds);

    return hours + ':' + minutes + ':' + seconds;
}

export function getTimerStringSmall(sec) {

    // console.log(sec)
    // console.log(Math.floor(getDate().getDate()/1000 - sec))

    if (!sec || sec < 0)
        sec = 0;

    sec = Math.floor(sec)

    // let hours = Math.floor(sec / (60 * 60));
    let minutes = Math.floor(sec / 60) % 60;
    let seconds = Math.floor(sec % 60);

    // hours = (hours.toString().length === 1 ? '0' + hours : hours);
    minutes = (minutes.toString().length === 1 ? '0' + minutes : minutes);
    seconds = (seconds.toString().length === 1 ? '0' + seconds : seconds);

    return minutes + ':' + seconds;
}

export function getMoneyString(count) {

    // console.log('Money: ' + count)

    count = (count + '').replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    // console.log('Money: ' + count)

    return count;
}


export let getDate = () => {
    return new Date();
}
