import * as React from 'react';
import { Text, View } from 'react-native';
import { styles } from '../styles/styles'
import { getTimerStringSmall, utils} from '../styles/utils';
import { getDate } from '../services/time'

const Info = (props) =>  {
    const [ time, setTime ] = React.useState(props.time);
    const { title, descriptionOne, descriptionTwo } = props;

    React.useEffect(() =>{
        let timerId = setInterval(() => {
                setTime(600 - (getDate() / 1000 - time))
        }, 1000);
        return () =>  clearInterval(timerId)
    },[])

        return (
            <View style={[styles.infoBlock,{paddingVertical:utils.padding*2}]}>
                <Text style={[styles.textBig,{paddingVertical: utils.padding/2}]}>{title}</Text>
                <Text style={[styles.textNormal,{paddingVertical: utils.padding/2}]}>{descriptionOne}</Text>
                <Text style={[styles.textNormal,{paddingVertical: utils.padding/2}]}>{descriptionTwo}</Text>
                {time && <Text style={[styles.textGiant,{paddingVertical: utils.padding/2}]}>{getTimerStringSmall(time)}</Text>}
            </View>
        );
}


export default Info;
