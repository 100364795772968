import { useEffect, useLayoutEffect, useState } from "react";
import { Modal, TouchableOpacity } from "react-native";
import { ScrollView, View, Text, RefreshControl, ActivityIndicator, FlatList } from "react-native";
import OutOrder from "../../components/itemOutOrder";
import { getRedemptionOrders } from "../../services/network";
import { styles } from "../../styles/styles";
import { palette, utils } from "../../styles/utils";
import RefreshIcon from '../../assets/icons/refresh';
import { useNavigation } from "@react-navigation/native";
const OutOrders = () => {
   const [orders, setOrders] = useState([])
   const [refresh, setRefresh] = useState(false)
   const navigation = useNavigation()
    useEffect(() => {
        onRefresh()
    }, [])
    useLayoutEffect(() =>{
        const list = navigation.addListener('focus', () => {
            onRefresh()
        })
        return list;
    },[])
    const onRefresh = () => {
        setRefresh(true)
        getRedemptionOrders().then(orders => {
            setOrders(orders.orders)
        })
        setTimeout(() => {
            setRefresh(false)
        }, 3000);
    }
    return(
      <ScrollView style={{flex: 1,backgroundColor:palette.white, padding: utils.padding}}
       refreshControl={
          <RefreshControl
            refreshing={refresh}
            onRefresh={() => onRefresh()}
            tintColor={palette.yellow}
            size={utils.padding * 2}
          />
        }
        >
   <TouchableOpacity onPress={() => onRefresh()} style={{position: 'absolute', top: utils.padding * 2, alignSelf: 'center'}}>
            <RefreshIcon/>
          </TouchableOpacity>

<View style={{marginTop: utils.padding*5}}>
                <FlatList
                    data={orders}
                    keyExtractor= {(item, index) => item.id}
                    renderItem={({ item, index }) => {
                        return <OutOrder key={index} order={item}/>
                    }}/>

</View>

          {!orders.length &&
                 <View style={{flex: 1}}>
                     <Text style={[styles.textBig, styles.textCenter]}>{'На данный момент заказов нет, это может быть связано с Вашей удаленностью от города'}</Text>
             </View>
             }
             <Modal
                visible={refresh}
                transparent={true}>
                    <View style={{
                         flex: 1,
                         justifyContent: "center",
                         alignItems: "center",
                         marginTop: 22
                    }}>
                    <View
                            style={{
                                margin: 20,
                                backgroundColor: "white",
                                borderRadius: 20,
                                padding: 35,
                                alignItems: "center",
                                shadowColor: "#000",
                                shadowOffset: {
                                width: 0,
                                height: 2
                                },
                                shadowOpacity: 0.25,
                                shadowRadius: 4,
                                elevation: 5
                            }}>
                         {refresh && <ActivityIndicator size={'large'} color={'#fdd107'}/>  }
                    </View>
                    </View>
    </Modal>
        </ScrollView>
    )
}

export default OutOrders;