import * as React from "react"
import Svg, { Path, G } from "react-native-svg"

function BackgroundLogin() {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg"
    width={194.667}
    height={168}
    viewBox="0 0 146 146">
    <Path
      fill="#d51317"
      d="M0 46.4V12C0 5.4 5.4 0 12 0h64.5c6.6 0 12 5.4 12 12v34.4c0 6.6-5.4 12-12 12h-9.7v-8h9.7c2.2 0 4-1.8 4-4V12c0-2.2-1.8-4-4-4H12c-2.2 0-4 1.8-4 4v34.4c0 2.2 1.8 4 4 4h37.6l17.1 20.4v12.4L45.9 58.4H12c-6.6 0-12-5.3-12-12"
    />
    <G fill="#555655">
      <Path d="M112.8 76.4c-3.8 0-6.9-3.1-6.9-6.9 0-3.8 3.1-6.9 6.9-6.9 3.8 0 6.9 3.1 6.9 6.9 0 3.8-3.1 6.9-6.9 6.9M32.4 76.4c-3.8 0-6.9-3.1-6.9-6.9 0-3.8 3.1-6.9 6.9-6.9 3.8 0 6.9 3.1 6.9 6.9 0 3.8-3.1 6.9-6.9 6.9M100.4 27.3v19.2c0 2.2 1.8 4 4 4H129c2.2 0 4-1.8 4-4 0-1.3-.6-2.4-1.5-3.1l-24.3-19c-.9-.8-1.7-1.1-2.7-1.1-2.3 0-4.1 1.8-4.1 4m-7.9 19.2V27.3c0-6.6 5.4-12 12-12 2.9 0 5.6 1 7.6 2.7l24.3 19c2.8 2.2 4.6 5.6 4.6 9.4 0 6.6-5.4 12-12 12h-24.6c-6.6 0-11.9-5.3-11.9-11.9" />
    </G>
  </Svg>
  )
}

export default BackgroundLogin
