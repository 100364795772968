import React from 'react';
import { Text, View } from 'react-native';
import { styles } from '../styles/styles'
import { utils } from '../styles/utils';

const TerminalCard = (props) => {

        const { text, allDay } = props;

        return (
            <View style={{ marginHorizontal: - utils.padding, borderRadius: utils.padding, borderBottomWidth: 0.5, padding: utils.padding/2, borderBottomColor: utils.gray}}>

                <View style={{flexDirection: 'row', justifyContent: 'space-between', marginBottom: -utils.padding/1.2, marginTop: utils.padding}}>
                    <Text style={[styles.textNormal,{paddingVertical: utils.padding/2}]}>{text}</Text>
                </View>

                {allDay ? <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                    <Text style={[styles.textNormal]}> </Text>
                    <Text style={[styles.textNormal]}>24ч</Text>
                </View> : null}

            </View>
        );
}


export default TerminalCard;
