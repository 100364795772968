import './App.css';
import { NavigationContainer } from '@react-navigation/native';
import { ThemeProvider } from "styled-components";
import LoginScreen from './screens/loginScreen';
import theme from './styles/colors';

function App() {
  return (
      <ThemeProvider theme={theme}>
          <NavigationContainer theme={theme}>
              <LoginScreen/>
          </NavigationContainer>
        </ThemeProvider>
 
  );
}

export default App;
