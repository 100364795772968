const HOME_ROUTES = {
    Orders: 'Заказы',
    Balance: 'Баланс',
    CurrentOrder: 'Заказ',
    Profile: 'Профиль',
    OutOrders: 'Доступные заказы' ,
    Income: 'Заработки' ,
  };
  
  export default HOME_ROUTES;
  