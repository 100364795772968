import * as React from 'react';
import { View, Text, Modal, StyleSheet, TouchableOpacity, TextInput } from 'react-native';
import CopyIcon from '../../assets/icons/copy'
import { closeOrder, loadingPoint, ordersGet } from '../../services/network';
import { getDate, getOnlyTimeString } from '../../services/time';
import Timer from './timer';
import Clipboard from '@react-native-clipboard/clipboard';
import PoPup from '../../components/PopUp';
import Info from '../../components/info';
import { setOrderSocket } from '../../services/socket';
import { activateTimerEvent, activateButtonArrivalEvent } from '../../navigation/tabs';
import { ScrollView } from 'react-native';
import { getMoneyString, palette, utils } from '../../styles/utils';
import { stylesCard } from '../../styles/styles';
import useForceUpdate from 'use-force-update';


const Separator = () => {
    return(
        <View style={{backgroundColor:'#ebebeb', height: utils.padding/2, marginHorizontal: -utils.padding*2}}/>
    )
}

const CurrentOrder = (props) => {
const [ order, setOrder ] = React.useState(props.order)
const [ errors, setErrors ] = React.useState('')
// const [ dataSocket, setDataSocket ] = React.useState('')
const [ errorsCloseOrder, setErrorsCloseOrder ] = React.useState('')
const [ address, setAddress ] = React.useState('')
const [ visiblePopUp, setVisiblePopUp ] = React.useState(false);
const [ need_client_cash, setClientCash ] = React.useState('');
const forceUpdate = useForceUpdate();

const { visible, show } = props;

const setCash = (number) =>{
    setClientCash(number)
}
const copyToClipboard = (text) => {
    setAddress(text)
    setVisiblePopUp(true)
        // order.route.location[0].region + ' ' + order.route.location[0].address)
    Clipboard.setString(text);
    setTimeout(() => {
        setVisiblePopUp(false)
    }, 4000);
  };
  const handlePressClose = (order) => {
      // TODO
    // if(need_client_cash < order.need_client_cash){
    //     setErrorsCloseOrder('Введенная сумма меньше суммы заказа')
    // }
    closeOrder(order.id, need_client_cash).then((responseJson) => {

        let updateOrder = props.order;

        if(responseJson.request_id){
            updateOrder.last_close_request = {
                created_at: getDate().getTime() / 1000,
                status_id: "WAIT_DONE",
            }
            setOrder(updateOrder);
            forceUpdate();
            setErrorsCloseOrder('')
            }
        if(responseJson.code){
            setErrorsCloseOrder(responseJson.message)
        }
    })
  }

const handlePressArrived = () => {
        setTimeout(() => {
            loadingPoint(order.id).then(responseJson => { 
                if (responseJson.code){
                    setErrors(responseJson.message)
                    setTimeout(() => {
                        setErrors('')
                    }, 15000);
                } else {
                    activateTimerEvent(activateTimer);
                    setTimeout(() => {
                        ordersGet(order.id).then((orderItem) =>{
                            console.log(orderItem)
                            order.actual_arrival_time = orderItem.actual_arrival_time
                            activateButtonArrivalEvent(setOrder(orderItem))
                            setOrder(orderItem);
                         })
                    }, 500);
                }
            })
        }, 1500)
}
const activateTimer = () => {
    let order = props.order;
    order.actual_arrival_time = getDate().getTime() / 1000;
    setTimeout(() => {
        setOrder(order)
    }, 1000);
}

React.useEffect(() => {
    setOrderSocket(order.id, updateOrder)
}, [order.id])  

const updateOrder = (data) =>{
    if(order.status === 'NEW') {

        if (data.distanceOnOrderActual)
            order.distance_on_order_actual = data.distanceOnOrderActual

        if (data.additionalKilometersActual)
            order.additional_kilometers_actual = data.additionalKilometersActual

        if (data.total)
            order.counter_total = data.total;

        if (data.complete_time)
            order.complete_time = data.complete_time;

        if (data.dateStart)
            order.actual_arrival_time = data.dateStart;

        if (data.needClientCash)
            order.need_client_cash = data.needClientCash;


        if (data.tariffAdditionalHourPrice)
            order.tariff_price_hour = data.tariffAdditionalHourPrice;

        if (data.free_additional_time)
            order.free_additional_time = data.free_additional_time;
            
    }
}
    return(
        <View style={styles.centeredView}>
        
            <Modal 
                animationType='slide'
                transparent={true}
                visible={visible}
            >
        {visiblePopUp && 
            <View style={{flex: 1,position: 'absolute', zIndex: 1,width: '100%',height: 0,marginTop: 30,}}>
                <PoPup text= {address +'\n\n Адрес скопирован'}/>
            </View>
        }
                <View style={styles.centeredView} >
                    <View style={styles.modalView} >
                        <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                            <Text style={{paddingLeft:14, fontSize:16}}>
                                {order.special_number}
                            </Text>
                            <TouchableOpacity onPress={() => {
                                show(false);
                                clearInterval();
                                }}>
                                <Text style={{fontSize:16, fontWeight:'bold'}}>
                                        {'Закрыть'}
                                    </Text>
                                </TouchableOpacity>
                        </View>
                        <View style={{flexDirection:'row', justifyContent:'space-around', borderBottomWidth:1, borderTopWidth:1, marginTop:14, marginHorizontal: -14}}>
                            <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                                <Text style={{padding:14}}>{'Грузчиков '}</Text>
                                <Text style={{padding:14}}>{order.movers_number}</Text>
                            </View>
                            <View style={{borderRightWidth:0.5}}/>
                            <View style={{ flexDirection:'row', justifyContent:'space-between'}}>
                                <Text style={{padding:14}}>{'Пассажиров '}</Text>
                                <Text style={{padding:14}}>{order.passengers_number}</Text>
                            </View>
                        </View>
               
        {!order.actual_arrival_time && order.can_set_arrival_time_to_loading_point &&
        <View style={{paddingTop:14}}>
  {order.status === 'NEW' &&
                <View style={{flexDirection:'row', justifyContent:'space-between',paddingBottom:utils.padding,marginBottom:utils.padding, borderBottomWidth:0.5, marginHorizontal: -utils.padding, paddingHorizontal:utils.padding}}>
                    <Text>{'Заказ назначен на'}</Text>
                    <Text>{getOnlyTimeString(order.expected_arrival_time)}</Text>
                    </View>
    }
            <Text style={{textAlign:'center', fontSize: 16}}>{"Кнопка станет активной"}</Text>
            <Text style={{textAlign:'center', fontSize: 16}}>{"по прибытию на точку погрузки"}</Text>

            <View style={{paddingHorizontal:14, margin: 14}}>
                <TouchableOpacity onPress={()=> handlePressArrived()} style={{backgroundColor:(order.car_is_in_arrival_radius ? palette.yellow : palette.lightgray), borderRadius:8}} 
                disabled={!order.car_is_in_arrival_radius}
                >
                    <Text style={{textAlign:'center', padding:14}}>{'Прибыл'}</Text>
                </TouchableOpacity>
            </View>
            {errors && 
                <Text style={{paddingVertical:8, textAlign:'center',fontSize:14}}>
                        {errors}
                    </Text>
            }
            <Separator />
            </View>
        }
        <ScrollView style={{marginHorizontal: -utils.padding, paddingHorizontal:utils.padding}} showsVerticalScrollIndicator={false}>
        <Separator/>
      

        {order.actual_arrival_time > 0 && <View>

        {(props.order.last_close_request && props.order.last_close_request.created_at && props.order.last_close_request.status_id === "WAIT_DONE") ?

            <View style={stylesCard.containerInfo}>

                <Info 
                    title={'Покиньте зону разгрузки'}
                    descriptionOne={'Вы находитесь в радиусе ' + order.discharge_radius + ' м'}
                    descriptionTwo={'осталось времени'}
                    time={order.last_close_request.created_at} />
            </View>

            :
                <Timer order={order} />
        }
        </View>
        }
     
        {errorsCloseOrder && 
            <View>
                <Text style={{paddingVertical:8, textAlign:'center',fontSize:14}}>
                            {errorsCloseOrder}
                        </Text>
            </View>
            
        }

        {order.last_close_request.status_id === 'WAIT_DONE'  &&
            <View style={{flexDirection:'row', justifyContent:'space-between', borderTopWidth:0.5, borderBottomWidth: 0.5, marginHorizontal:-utils.padding, padding:utils.padding}}>
               <Text style={{fontWeight:'500', fontSize: 16}}>{'Взять наличными'}</Text>
               <Text style={{fontWeight:'500', fontSize: 16}}>{getMoneyString(order.need_client_cash) + ' руб.'}</Text>
            </View>
        }
        {order.actual_arrival_time > 0 && order.status === 'NEW' && order.last_close_request.status_id !== 'WAIT_DONE' && 
            <View style={{flexDirection:'row', justifyContent:'space-between', width:'100%'}}>
                <TextInput maxLength={6} keyboardType={'numeric'} style={{borderTopRightRadius:0,borderBottomRightRadius:0, width:'60%',backgroundColor:'#ebebeb', padding: utils.padding/2, borderRadius: utils.padding/2}} placeholder={'Полученно наличными'} value={need_client_cash} onChangeText={(need_client_cash) => setCash(need_client_cash)}/>
                <TouchableOpacity onPress={() => handlePressClose(order)} style={{backgroundColor:palette.yellow, padding:utils.padding, width:'40%',borderTopRightRadius:utils.padding/2,borderBottomRightRadius:utils.padding/2}}>
                    <Text style={{textAlign:'center'}}>{'Завершить'}</Text>
                </TouchableOpacity>
            </View>
        }
                <View>
                    <View style={{flexDirection:'row', justifyContent:'space-between',paddingTop: 14, paddingBottom: 14}}>
                        <Text style={{fontSize:18, fontWeight:'bold'}}>{'Маршрут'}</Text>
                            <View style={{justifyContent:'center'}}>
                                <Text style={{fontSize:16, color:'gray'}}>{'(' + order.route.distance_meters/1000 + 'км.)'}</Text>
                            </View>
                    </View>
                    {order.route && order.route.locations.map((index, i) => {
                        return(
                            <View key={i} style={{paddingTop: 16,flexDirection:'row', justifyContent: 'space-between',borderBottomWidth:0.5, borderColor:'#ccc', }}>
                            <View>
                                <Text key={index.region} style={{fontSize:10, color:'gray', maxWidth: 300}}>{index.region}</Text>
                                <Text key={index.address} style={{fontSize:14, paddingVertical: 4, maxWidth: 300}}>{index.address}</Text>
                            </View>
                            <TouchableOpacity onPress={() => copyToClipboard(index.region + ' ' + index.address)}>
                                    <CopyIcon/>
                                </TouchableOpacity>
                        </View>
                        )
                    })}
                
                </View>

                <Separator/>

                    <View style={{flexDirection:'row', justifyContent:'space-between',padding:14, marginHorizontal:-utils.padding, borderBottomWidth: 0.5, borderColor:palette.gray}}>
                        <Text>{'Клиент'}</Text>
                        <Text>{`${order.client_name}`}</Text>
                        </View>
                        {order.operator_comment && 
                            <View style={{padding:14, marginHorizontal:-utils.padding}}>
                                <Text>{'Комментарий'}</Text>
                                <Text>{`${order.operator_comment}`}</Text>
                            </View>
                        }
        </ScrollView>
        

                    </View>
                </View>
            </Modal>
            </View>
    )

}

const styles = StyleSheet.create({
    centeredView: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      marginTop: 22
    },
    modalView: {
      margin: 20,
      marginTop:12,
      marginBottom: -10,
      backgroundColor: "white",
      borderRadius: 20,
      padding: 12,
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2
      },
      shadowOpacity: 5,
      shadowRadius: 14,
      elevation: 5,
      width:'100%',
      height:'100%'
    },
    button: {
      borderRadius: 20,
      padding: 10,
      elevation: 2
    },
    buttonOpen: {
      backgroundColor: "#F194FF",
    },
    buttonClose: {
      backgroundColor: "#2196F3",
    },
    textStyle: {
      color: "white",
      fontWeight: "bold",
      textAlign: "center"
    },
    modalText: {
      marginBottom: 15,
      textAlign: "center"
    }
  });

export default CurrentOrder;