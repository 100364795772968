import * as React from "react"
import Svg, { Path } from "react-native-svg"

function BalanceIcon(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512.16 512.16"
      {...props}
    >
      <Path fill={props.focused === 'true' ? '#fdd107' : 'black'} d="M273.067 337.147H409.6c5.12 0 8.533-3.413 8.533-8.533v-76.8c0-5.12-3.413-8.533-8.533-8.533H273.067c-5.12 0-8.533 3.413-8.533 8.533v76.8c-.001 5.119 3.413 8.533 8.533 8.533zm8.533-76.8h119.467v59.733H281.6v-59.733zM42.667 226.213h68.267c5.12 0 8.533-3.413 8.533-8.533s-3.413-8.533-8.533-8.533H42.667c-5.12 0-8.533 3.413-8.533 8.533s3.413 8.533 8.533 8.533zM145.067 226.213h68.267c5.12 0 8.533-3.413 8.533-8.533s-3.413-8.533-8.533-8.533h-68.267c-5.12 0-8.533 3.413-8.533 8.533s3.413 8.533 8.533 8.533zM42.667 260.347H153.6c5.12 0 8.533-3.413 8.533-8.533s-3.413-8.533-8.533-8.533H42.667c-5.12 0-8.533 3.413-8.533 8.533s3.413 8.533 8.533 8.533zM213.333 243.28h-25.6c-5.12 0-8.533 3.413-8.533 8.533s3.413 8.533 8.533 8.533h25.6c5.12 0 8.533-3.413 8.533-8.533s-3.413-8.533-8.533-8.533z" />
      <Path fill={props.focused === 'true' ? '#fdd107' : 'black'} d="M504.32 137.467c-5.973-7.68-13.653-11.947-23.04-12.8l-20.48-2.482V81.147c0-18.773-15.36-34.133-34.133-34.133H34.133C15.36 47.013 0 62.373 0 81.147v273.067c0 15.413 10.357 28.518 24.453 32.718-.43 17.262 12.631 32.248 30.161 33.842l394.24 44.373h3.413c17.067 0 32.427-12.8 34.133-29.013l25.6-273.92c.853-8.534-1.707-17.921-7.68-24.747zm-487.253-30.72h426.667v51.2H17.067v-51.2zM34.133 64.08h392.533c9.387 0 17.067 7.68 17.067 17.067v8.533H17.067v-8.533c0-9.387 7.68-17.067 17.066-17.067zM17.067 354.213v-179.2h426.667v179.2c0 9.387-7.68 17.067-17.067 17.067H34.134c-9.387 0-17.067-7.68-17.067-17.067zM494.933 158.8l-25.6 273.92c-.853 9.387-9.387 16.213-18.773 15.36L57.173 403.707c-8.533-.853-14.507-7.68-15.36-15.36h384.853c18.773 0 34.133-15.36 34.133-34.133V138.32l19.627 1.707c4.267 0 8.533 2.56 11.093 5.973 2.561 3.413 4.268 8.533 3.414 12.8z" />
    </Svg>
  )
}

export default BalanceIcon
