import { useNavigation } from "@react-navigation/native";
import { Alert } from "react-native";
import { Text, TouchableOpacity } from "react-native";
import { View } from "react-native";
import Place from '../assets/icons/place';
import HOME_ROUTES from "../navigation/home_routes";
import { buyRedemptionOrders } from "../services/network";
import { getMoneyString, palette, utils } from "../styles/utils";

const OutOrder = props => {
const order = props.order;
const navigation = useNavigation();


    const buyOrder = (id) => {
        buyRedemptionOrders(id).then((repsonseJson) => {
            if(repsonseJson.id){
                navigation.navigate(HOME_ROUTES.Orders)
            }
            if(repsonseJson.code){
                Alert.alert('Выкуп невозможен', 'Это может быть связано с низким балансом Вашего счета, рекомендуемый баланс 5000р',  [
                    {
                      text: 'Пополнить',
                      onPress: () => navigation.navigate(HOME_ROUTES.Balance),
                      style: "default"
                    },
                    { text: "Ок", onPress: () => console.log("OK Pressed") }
                  ]
                  )
            }
        })
    }

    return(
        <View style={{marginHorizontal: utils.padding, marginVertical: utils.padding / 2, borderWidth: 0.5, borderColor: palette.gray, borderRadius: utils.padding}}>
        <View style={{padding: utils.padding * 2, paddingVertical: utils.padding, borderBottomWidth: 0.5, flexDirection: 'row', justifyContent: 'space-between'}}>
            <View>
                <Text style={{fontWeight: 'bold', fontSize: 20}}>{`${order.distance} км от вас`}</Text>
                <Text style={{fontWeight: 'bold', fontSize: 20}}>{order.sectorFrom === null ? '' : `(${order.sectorFrom})`}</Text>
            </View>
                <View style={{justifyContent:'center'}}>
                    <Place/>
                </View>
        </View>
        {order.operator_comment ? (
        <View style={{padding: utils.padding, flexDirection: 'row', paddingHorizontal: utils.padding* 2}}>
            <Text style={{fontWeight: 'bold', paddingLeft: utils.padding}}>{order.operator_comment} </Text>
        </View>
        )
        : null
        }
       {order.distance_meters > 0  && (
        <View style={{paddingHorizontal: utils.padding * 2, paddingTop: utils.padding / 2}}>
            <Text style={{fontWeight: 'bold'}}>{`Расстояние по заказу: ${Math.abs(order.distance_meters)/1000} км`} </Text>
        </View>
       )} 
        {order.orderTotal > 0 && (
            <View style={{paddingHorizontal: utils.padding * 2, paddingTop: utils.padding / 2}}>
                <Text style={{fontWeight: 'bold'}}>{`Стоимость заказа: ${order.orderTotal} руб.`} </Text>
            </View>
        )}
        {order.priceAddHour > 0 && (
            <View style={{paddingHorizontal: utils.padding * 2, paddingTop: utils.padding / 2}}>
                <Text style={{fontWeight: 'bold'}}>{`Каждый доп.час: ${order.priceAddHour} руб.`} </Text>
            </View>
        )}
        {order.priceAddKm > 0 && (
            <View style={{paddingHorizontal: utils.padding * 2, paddingTop: utils.padding / 2}}>
                <Text style={{fontWeight: 'bold'}}>{`Каждый доп.км: ${order.priceAddKm} руб.`} </Text>
            </View>
        )}

        <View style={{paddingHorizontal: utils.padding * 2, paddingTop: utils.padding / 2}}>
               {order.movers > 0 ? <Text style={{fontSize: 22}}>{`${order.movers} грузчика`}</Text> : <Text style={{fontSize: 22}}>{`без грузчиков`}</Text>}
        </View>
            <TouchableOpacity onPress={() => buyOrder(order.orderId)} style={{alignSelf: 'flex-start', marginHorizontal: utils.padding * 2, marginVertical: utils.padding / 2, borderWidth: 0.5, borderColor: palette.blue, marginBottom: utils.padding}}>
                <Text style={{padding: utils.padding / 2, color: palette.blue, fontSize: 16}}>{`Выкупить за ${getMoneyString(order.total)} ₽`}</Text>
            </TouchableOpacity>
        </View>
    )
}
export default OutOrder;