import * as React from 'react';
import { ScrollView } from 'react-native';
import { View, Text } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { balanceList } from '../../services/network';
import { getMoneyString } from '../../services/time';
import { palette, utils } from '../../styles/utils';
import BalanceCard from './balanceCard';
import ReplenishBalance from './balanceModal';
import { useNavigation } from '@react-navigation/native';

const BalanceScreen = () => {
const [ balanceHistory, setBalanceHistory ] = React.useState([]);
const [ balance, setBalance ] = React.useState([]);
const [ writeOffs, setWriteOffs ] = React.useState([]);
const [ replenishment, setReplenishment ] = React.useState([]);
const navigation = useNavigation();

const [ visibleModal, setModal] = React.useState(false);

React.useEffect(() => {
    balanceList().then((responseJson) => {
        setBalanceHistory(responseJson.balance_history)
        setBalance(responseJson)
        let w = 0;
        let r = 0;
        responseJson['balance_history'].forEach(function (item) {
            w += item.sum < 0 ? item.sum : 0;
            r += item.sum > 0 ? item.sum : 0;
        });
        setWriteOffs(w)
        setReplenishment(r)
    })
},[])
    return (
      <View style={{backgroundColor:palette.lightgray, flex: 1,justifyContent: 'center'}}>
          <ScrollView style={{paddingBottom: utils.padding*4}} showsVerticalScrollIndicator={false}>
            <View style={{ backgroundColor:'#fff', borderRadius:10, margin:4,marginVertical:8}}>
                <Text style={{color:'gray', paddingTop:8, textAlign:'center'}}>{'Итого'}</Text>
                <Text style={{fontWeight:'700', padding:4, paddingTop:8, fontSize: 18, textAlign:'center'}}>{getMoneyString(balance.balance) + ' руб.'}</Text>
                {balance.pledge > 0 && 
                    <View style={{ backgroundColor:palette.lightgray, borderRadius:8, marginBottom:8, marginTop: 12, alignSelf:'center'}}>
                        <Text style={{padding:8, paddingVertical:4, textAlign:'center'}}>{'В резерве ' + getMoneyString(balance.pledge) + ' руб.'}</Text>
                    </View>
                }
                <View style={{borderTopWidth:0.5, width:'100%', borderColor:'#ccc'}}/>
                <View style={{flexDirection:'row', justifyContent:'space-around', margin: 14}}>
                        <View style={{alignItems:'center'}}>
                            <Text style={{color:'gray', fontSize:10}}>{'Списания'}</Text>
                            <Text style={{color:'red', paddingTop: 4}}>{getMoneyString(writeOffs) + ' руб.'}</Text>
                        </View>
                        <View style={{borderLeftWidth:0.5, marginVertical: -14, borderColor:'#ccc'}}/>
                                <View style={{alignItems:'center'}}>
                                    <Text style={{color:'gray', fontSize:10}}>{'Пополнения'}</Text>
                                    <Text style={{color:'green', paddingTop: 4}}>{getMoneyString(replenishment) + ' руб.'}</Text>
                                </View>
                </View>
            </View>
            {balanceHistory && balanceHistory.map((index) => {
                return <BalanceCard navigation={navigation} key={index.id} balanceItem={index}/>
            })}    
        </ScrollView>
        <View style={{padding:14, backgroundColor:'transparent', position:'absolute', bottom: 0, left:0, right:0}}>
             <TouchableOpacity onPress={() => setModal(true)} style={{backgroundColor:palette.yellow, alignItems:'center', borderRadius:utils.padding/2}}>
                 <Text style={{padding:utils.padding}}>{'Пополнить депозит'}</Text>
             </TouchableOpacity>
         </View>
            {   visibleModal &&
                    <ReplenishBalance visible={visibleModal} onShow={setModal}/>
            }
      </View>
    );
  }

  export default BalanceScreen;