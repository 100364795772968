import React from 'react';
import { getDateString, getMoneyString } from '../../services/time';
import { View, Text } from 'react-native';

const OrderCard = (props) => {
const order = props.order;

    return (
        <View key={order} style={{margin: 14, padding: 14, borderWidth: 0.5, borderRadius: 4}}>
        <View style={{flexDirection:'row', justifyContent:'space-between'}}>
           <Text style={{color:'gray', fontSize: 12}}>
                {getDateString(order.expected_arrival_time)}
            </Text>
            <Text style={{fontWeight:'bold'}}>
              {getMoneyString(order.counter_total) + ' руб.'}
              </Text>                    
          </View>
          <Text style={{fontSize:12, paddingVertical: 8}}>{order.special_number}</Text>
            <View style={{borderBottomWidth:0.5, borderColor:'#ccc'}}>
                  <Text style={{fontSize:10, color:'gray',}}>{order.route.locations[0].region}</Text>
                  <Text style={{fontSize:14, paddingVertical: 4}}>{order.route.locations[0].address}</Text>
              </View>
              {order.route.locations[1] && 
                <View>
                    <Text style={{fontSize:10, color:'gray', paddingTop: 4}}>{order.route.locations[1].region}</Text>
                    <Text style={{fontSize:14, paddingTop: 4}}>{order.route.locations[1].address}</Text>
                </View>
                }
      </View>
    )
}

export default OrderCard;