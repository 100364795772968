import * as React from 'react';
import { View, Text, Modal, StyleSheet, TouchableOpacity, TextInput, ActivityIndicator } from 'react-native';
import TerminalCard from '../../components/terminalCard';
import { driverGetData, replenishDeposit, replenishDepositAlternative, terminalsList } from '../../services/network';
import { palette, utils } from '../../styles/utils';

const ReplenishBalance = (props) =>{
const { visible, onShow } = props;
const [ summ, setSumm] = React.useState('')
const [ terminals, setTerminals] = React.useState([])
const [ errorSumm, setErrorSumm] = React.useState(false)
const [ indicator, setIndicator] = React.useState(false)
React.useEffect(() => {
    terminalsList().then((terminals) => {
        setTerminals(terminals);
    })

}, [])
const openWindowWithPost = (url, data) => {
    var form = document.createElement("form");
    form.target = "_blank";
    form.method = "POST";
    form.action = url;
    form.style.display = "none";

    for (var key in data) {
        var input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = data[key];
        form.appendChild(input);
    }

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
} 

const payment = (type) => {
    if (summ > 0) {
        setIndicator(true) 
               driverGetData().then((driver) => {
                    setTimeout(() => { 
                        if (type === 'alternative') {
                            replenishDepositAlternative(driver.id, summ).then((responseJson) => {
                                console.log(responseJson)
                                openWindowWithPost(responseJson.url, {
                                        p: "view.map",
                                    });
                            })
                        }
                        else {
                            replenishDeposit(driver.id, summ).then((responseJson) => {
                                console.log(responseJson)
                                openWindowWithPost(responseJson.url, {
                                        p: "view.map",
                                    });
                            })
                        }
                        setIndicator(false);
                    }, 500);
                })
    }
    else {
        setErrorSumm(true)
    }
}


    return (
        <View style={styles.centeredView} >
            <Modal 
                animationType='slide'
                transparent={true}
                visible={visible}>
                    <View style={styles.centeredView} >
                            <View style={styles.modalView} >
                                <View style={{flexDirection:'row', justifyContent:'flex-end'}}>
                                    <TouchableOpacity onPress={() => onShow(false)}>
                                        <Text style={{fontSize:16, fontWeight:'bold'}}>
                                                {'Закрыть'}
                                            </Text>
                                        </TouchableOpacity>
                                </View>

                                <View style={{padding: utils.padding}}>
                                        {terminals.slice(0, 3).map((terminal, index) => {
                                               let text = 'Каждый день'
                                               let allDay = terminals[index].data.includes(text)
                           
                                            return (
                                                <TerminalCard key={index}
                                                    allDay={allDay}
                                                    text={
                                                        terminals[index].data
                                                            .replace(/<br>/g, '\n')
                                                            .replace(/<br \/>/g, '\n')
                                                            .replace(/\n$/, '')
                                                            .replace('\n' + text, '')
                                                } />
                                            )
                                        })}
                                </View>
                                {indicator && 
                     <ActivityIndicator size={'large'} color={'#fdd107'}/> 
                     }
                                <View style={{flex:1,marginTop: utils.padding*2}}>
                                    <TextInput keyboardType={'numeric'} onFocus={() => setErrorSumm(false)} style={{textAlign:'center', backgroundColor:palette.lightgray, marginHorizontal: -utils.padding/2, padding: utils.padding, borderRadius: utils.padding/2, borderColor: palette.red, borderWidth: errorSumm? 1 : 0}} placeholder={'Введите сумму'} value={summ} onChangeText={(value) => setSumm(value)}/>
                                    <TouchableOpacity onPress={() => payment()} activeOpacity={0.5} style={{margin: utils.padding, borderRadius:utils.padding/2, backgroundColor:palette.yellow, marginHorizontal: -utils.padding/2, marginTop: utils.padding*2}}>
                                        <Text style={{padding: utils.padding, textAlign:'center'}}>{'Перейти к оплате'}</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity onPress={() => payment('alternative')} activeOpacity={0.5} style={{margin: utils.padding,marginTop: utils.padding / 2, borderRadius:utils.padding/2, backgroundColor:palette.yellow, marginHorizontal: -utils.padding/2}}>
                                        <Text style={{padding: utils.padding, textAlign:'center'}}>{'Использовать другую систему оплаты'}</Text>
                                    </TouchableOpacity>
                                </View>
                                
                            </View>
                        </View>
                </Modal>
            </View>
    )
}


const styles = StyleSheet.create({
    centeredView: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      marginTop: 22,
    //   backgroundColor:palette.white
    },
    modalView: {
      margin: 20,
      marginTop:12,
      marginBottom: -10,
      backgroundColor: "white",
      borderRadius: 20,
      padding: 12,
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2
      },
      shadowOpacity: 5,
      shadowRadius: 14,
      elevation: 5,
      width:'100%',
      height:'100%'
    },
    button: {
      borderRadius: 20,
      padding: 10,
      elevation: 2
    },
    buttonOpen: {
      backgroundColor: "#F194FF",
    },
    buttonClose: {
      backgroundColor: "#2196F3",
    },
    textStyle: {
      color: "white",
      fontWeight: "bold",
      textAlign: "center"
    },
    modalText: {
      marginBottom: 15,
      textAlign: "center"
    }
  });

export default ReplenishBalance;