import React, { useRef, useEffect } from 'react';
import { Animated,TouchableOpacity, PanResponder, View, Text } from 'react-native';
import { palette, utils } from '../styles/utils';

const PoPup = (props) => {
  const hideAnimated = useRef(new Animated.Value(0)).current;
  const offset = [-450, 0];
  const pan = useRef(new Animated.ValueXY()).current;
  const panResponder = useRef(
    PanResponder.create({
      onStartShouldSetPanResponder: () => false,
      onStartShouldSetPanResponderCapture: () => false,
      onPanResponderTerminationRequest: () => true,
      onMoveShouldSetPanResponderCapture: (evt, gestureState) => true,
      onMoveShouldSetPanResponder: (gestureState) => {
        if (hideAnimated) {
          return true;
        }
        if (gestureState.dy > 22) {
          return true;
        }
        onClose();

        return true;
      },
      onPanResponderGrant: () => {
        pan.setOffset({
          x: 0,
          y: 0,
        });
        pan.setValue({ x: 0, y: 0 });
        onClose();

        return true;
      },
      onPanResponderMove: (gestureState) => {
        if (gestureState.dy < 0) {
          onClose();
          pan.setValue({ x: 0, y: gestureState.dy });
        }
      },
      onPanResponderRelease: () => {
        Animated.timing(pan, {
          toValue: { x: 0, y: -300 },
          duration: 400,
          useNativeDriver: true,
        }).start();
      },
    })
  ).current;
  const onClose = () => {
    Animated.timing(hideAnimated, {
      toValue: 0,
      duration: 700,
      useNativeDriver: true,
    }).start();
  };

  useEffect(() => {
        Animated.timing(hideAnimated, {
          toValue: 1,
          duration: 500,
          useNativeDriver: true,
        }).start();
  }, [hideAnimated]);

  return (
    <Animated.View
      style={{
        opacity: hideAnimated,
        transform: [
          {
            translateY: hideAnimated.interpolate({
              inputRange: [0, 1],
              outputRange: offset,
            }),
          },
        ],
      }}>
      <Animated.View
        style={[
          {
            transform: [{ translateY: pan.y }],
            marginTop: 40,
            marginHorizontal:utils.padding,
          },
        ]}
        {...panResponder.panHandlers}>
        <TouchableOpacity activeOpacity={1} style={{zIndex: 1, flex:1, backgroundColor:palette.white,
          shadowColor: palette.gray,
          borderColor:palette.lightyellow,
          borderWidth:0.5,
          borderRadius:utils.padding/2,
          shadowOffset: { width: 2, height: 2 },
          shadowOpacity: 3,
          shadowRadius: 2,
          elevation: 2,}}>
            <View>
                <TouchableOpacity activeOpacity={0.9} onPress={onClose}>
                  <Text style={{padding: utils.padding, textAlign:'center'}}>{props.text}</Text>
                </TouchableOpacity>
            </View>
        </TouchableOpacity>
      </Animated.View>
    </Animated.View>
  );
};

export default PoPup;
