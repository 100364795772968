import React from 'react';
import { Text, View } from 'react-native';
import { styles, stylesCard } from '../../styles/styles'
import { getMoneyString, getTimerString, utils } from "../../styles/utils";
import { getDate } from '../../services/time';

let timerId;
const Timer = (props) => {
    const [ time, setTime] = React.useState(0);
    
    React.useEffect(() => {
        timerId = setInterval(() => {
                setTime(time +1)
        }, 1000);

        return () => clearInterval(timerId);
    })
    const order = props.order;
    return (
        <View style={[stylesCard.container, styles.timerContainer,{ marginHorizontal:-utils.padding, borderWidth:1 }]}>

        <View style={styles.timerRow}>
            <Text style={[styles.textNormal, styles.timerValue]}>{'Тариф'}</Text>
            <Text
                style={[styles.textNormal, styles.timerValue]}>{getMoneyString(order.total_with_out_additional_time) + ' руб' + (order.duration > 0 ? ' / ' + order.duration + ' ' + 'час' + ' ' : '') + (order.need_calculate_actual_kilometers === true ? '/ ' + order.include_kilometers_on_order + ' ' + 'км' : '')}</Text>
        </View>

        <View style={styles.timerRowBig}>
            {/* <View style={(order.tariff_price_hour <= 0 ? [styles.timerRowBig,{justifyContent:'space-around'}] : styles.timerRowBig)}> */}
            {order.tariff_price_hour >= 0 || order.need_calculate_actual_kilometers === true && order.additional_actual_kilometer_price !== 0 ?
            <View style={{borderBottomWidth:1, width:'100%', position:'absolute', marginHorizontal:-utils.padding, bottom: utils.padding * 2.5}}/>
                // <View style={{ backgroundColor: utils.colors.buttonBackgroundDisabled, width: '110%', height: 1, position: 'absolute', bottom: utils.padding * 2.5,marginLeft: -8 }}></View>
                : null}

            <View>
                <Text style={[styles.textSmall, styles.textCenter]}>{'Длительность заказа'}</Text>
                <Text style={[styles.textGiant, styles.textCenter]}>

                    {
                        ((order.last_changed_status_at && order.status !== 'NEW') || (order.last_close_request && order.last_close_request.status_id === "WAIT_DONE")) ?

                            getTimerString(
                                (order.last_changed_status_at ? order.last_changed_status_at : order.last_close_request.created_at)
                                - order.actual_arrival_time) :
                            getTimerString(getDate().getTime() / 1000 - order.actual_arrival_time)
                    }

                </Text>

                {
                    order.need_calculate_actual_kilometers === true ?
                        <Text style={{ textAlign: 'center', paddingTop: 10, fontFamily: utils.font, fontSize: utils.fontSizeSmall, color: utils.colors.smallText }}>{'Проехал'}
                        </Text> : null
                }
                {
                    order.need_calculate_actual_kilometers === true ?
                        <Text style={{ textAlign: 'center', paddingVertical: 2, color: utils.colors.bigText, fontWeight: '400', fontSize: utils.fontSizeBig }}>{(order.distance_on_order_actual == null ? 0 : order.distance_on_order_actual) + ' ' + 'км'}
                        </Text> : null
                }
                {/* {order.tariff_price_hour > 0 || order.additional_actual_kilometer_price > 0 ? */}
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', textAlign: 'center', marginBottom: -utils.padding / 3 }}>
                    <Text style={{ paddingTop: utils.padding, color: 'black', fontSize: utils.fontSizeNormal }}>{'Доп.час' + ': '}</Text>
                    <Text
                        style={{ paddingTop: utils.padding, color: 'black', fontSize: utils.fontSizeNormal }}>{getMoneyString(order.tariff_price_hour) + ' руб'}</Text>
                </View>
            </View>
            <View>
                <Text style={[styles.textSmall, styles.textCenter]}>{'Допольнительное время'}</Text>
                <Text style={[styles.textGiant, styles.textCenter]}>

                    {
                        (order.last_changed_status_at && order.status !== 'NEW' || (order.last_close_request && order.last_close_request.status_id === "WAIT_DONE")) ?

                            ((order.last_changed_status_at ? order.last_changed_status_at : order.last_close_request.created_at) - order.actual_arrival_time - order.duration * 60 * 60 > 0 ?

                                getTimerString((order.last_changed_status_at ? order.last_changed_status_at : order.last_close_request.created_at) - order.actual_arrival_time - order.duration * 60 * 60) :
                                getTimerString(0))

                            :

                            (getDate().getTime() / 1000 - order.actual_arrival_time > order.duration * 60 * 60 ?

                                getTimerString(getDate().getTime() / 1000 - order.actual_arrival_time - order.duration * 60 * 60) :
                                getTimerString(0))

                    }

                </Text>

                {
                    order.need_calculate_actual_kilometers === true ?
                        <Text style={{ textAlign: 'center', paddingTop: 10, fontFamily: utils.font, fontSize: utils.fontSizeSmall, color: utils.colors.smallText }}>{'Доп.км'}
                        </Text> : null
                }
                {
                    order.need_calculate_actual_kilometers === true ?
                        <Text style={{ textAlign: 'center', paddingVertical: 2, color: utils.colors.bigText, fontWeight: '400', fontSize: utils.fontSizeBig }}>{order.additional_kilometers_actual + ' ' + 'км'}
                        </Text> : null
                }
                {
                    order.need_calculate_actual_kilometers === true && order.additional_actual_kilometer_price !== 0 ?
                        <View style={{ flexDirection: 'row', justifyContent: 'space-around', textAlign: 'center', marginBottom: -utils.padding / 3 }}>
                            <Text style={{ paddingTop: utils.padding, color: 'black', fontSize: utils.fontSizeNormal }}>{'Доп.км' + ':'}</Text>
                            <Text
                                style={{ paddingTop: utils.padding, color: 'black', fontSize: utils.fontSizeNormal }}>{getMoneyString(order.additional_actual_kilometer_price) + ' руб.'}</Text>
                        </View> : null
                }

            </View>
        </View>



        <View style={styles.timerRow}>
            <Text style={[styles.textNormal, styles.timerValue]}>{'Итоговая стоимость'}</Text>
            <Text
                style={[styles.textNormal, styles.timerValue,{fontWeight:'bold'}]}>
                {getMoneyString(order.counter_total)} {' руб.'}</Text>
        </View>

        {order.payment.prepaid > 0 && <View style={styles.timerRow}>
            <Text style={[styles.textNormal, styles.timerValue]}>{'Оплачено онлайн'}</Text>
            <Text
                style={[styles.textNormal, styles.timerValue]}>{getMoneyString(order.payment.prepaid)} {' руб.'}</Text>
        </View>}

        <View style={styles.timerRow}>
            <Text style={[styles.textNormal, styles.timerValue,{fontWeight:'bold', marginTop: -utils.padding}]}>{'Взять с клиента'}</Text>
            {order.payment.type === 'BANK' ?
                <Text
                    style={[styles.textNormal, styles.timerValue]}>{order.need_client_cash ? getMoneyString(order.need_client_cash) : 0} {' руб.'}</Text> :
                <Text

                    style={[styles.textNormal, { fontWeight: 'bold', color: 'black'}]}>
                    {getMoneyString(order.need_client_cash)} {' руб.'}</Text>}
        </View>

    </View>
);
}
export default Timer;