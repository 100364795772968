import { Platform, StyleSheet, Dimensions } from 'react-native';

import { getRatio, palette, utils } from './utils'

//GENERAL STYLES

export let styles;
export let stylesLogin;
export let stylesMain;
export let stylesHeader;
export let stylesMenu;
export let stylesTab;
export let stylesCard;
export let stylesPhoto;

let isAndroid = Platform.OS === 'android';
const isX = Platform.OS === "ios" && (Dimensions.get('window').width > 800 || Dimensions.get('window').height > 800);

export let generateStyleSheet = function () {

    styles = StyleSheet.create({

        //MAIN APP CONTAINER

        container: {
            paddingTop: (isAndroid ? 0 : (isX ? 44 : 20)),
            backgroundColor: utils.colors.background
        },

        //ITEMS CONTAINER

        itemsContainer: {
            flex: 1,
            backgroundColor: utils.colors.background,
        },

        //MAP

        map: {
            flex: 1,
        },

        //PAPERS CONTAINER TODO

        centerContainer: {
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'space-around',
        },

        //SHADOW

        shadow: {
            shadowColor: utils.colors.shadow,
            shadowOffset: { width: 2, height: 2 },
            shadowOpacity: 0.6,
            shadowRadius: 4,
            elevation: 6,
        },

        //ICON

        iconContainer: {
            flexDirection: 'column',
            justifyContent: 'space-around',
        },
        iconWhite: {
            color: 'white',
        },
        paymentIcon: {
            width: utils.height,
            height: utils.height / 1.5,
            marginLeft: utils.padding

        },


        //INPUT

        input: {
            width: '100%',
            minHeight: utils.height,
            padding: utils.padding,
            paddingTop: utils.padding / 2,
            paddingBottom: utils.padding / 2,
            marginBottom: utils.padding,
            // margin: '10%',
            backgroundColor: 'white',

            borderWidth: 1,
            borderColor: utils.colors.inputBorder,
            // borderBottomColor: utils.colors.tabBorder,

            borderRadius: utils.borderRadius
        },
        inputError: {
            borderWidth: 1,
            borderColor: utils.colors.important,
            // borderBottomColor: utils.colors.inputBorder,
        },

        // breakdown
        buttPhoto: {
            flexDirection: 'row',
            // alignItems:'center',
            justifyContent: 'center',
            margin: 15, padding: 15,
            backgroundColor: 'white',
            borderRadius: 5,
            shadowColor: "#000",
            shadowOffset: {
                width: 0,
                height: 4,
            },
            shadowOpacity: 0.30,
            shadowRadius: 4.65,
            elevation: 8,
        },
        PhotoContainer: {
            backgroundColor: 'white',
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 15,
            shadowColor: "#000",
            shadowOffset: {
                width: 0,
                height: 4,
            },
            shadowOpacity: 0.30,
            shadowRadius: 4.65,
            elevation: 8,
            marginVertical: utils.padding / 2,
            marginHorizontal: utils.padding / 2
        },


        //FUEL CARD CHECKBOX
        fuelCard: {

            paddingLeft: utils.padding,
            paddingRight: utils.padding,
            marginBottom: utils.padding,
            borderRadius: utils.borderRadius
        },

        //BUTTON

        buttonContainer: {
            flexDirection: 'row',
            justifyContent: 'center',
            height: utils.height,
            backgroundColor: utils.colors.buttonBackground,
            borderRadius: utils.borderRadius,

            borderWidth: 1,
            borderColor: palette.gray,
        },
        buttonContainerDisabled: {
            backgroundColor: utils.colors.buttonBackgroundDisabled,
        },
        buttonText: {
            lineHeight: utils.height,
            paddingLeft: utils.padding,
            paddingRight: utils.padding,

            textAlign: 'center',
            color: utils.colors.buttonText,
        },
        buttonIcon: {
            flexDirection: 'column',
            justifyContent: 'space-around',
            color: '#ffffff',
            paddingLeft: utils.padding,

        },
        buttonsRow: {
            flexDirection: 'row',
            justifyContent: 'space-around'
        },
        buttonsRowFill: {
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        buttonPadding: {
            paddingLeft: utils.padding,
            paddingRight: utils.padding,
        },

        mdButtonContainer: {
            position: 'absolute',
            right: utils.padding * 2,
            bottom: utils.padding * 2,
            width: 60,
            height: 60,
            zIndex: 1,
            overflow: 'hidden'
        },
        mdButton: {
            width: '100%',
            height: '100%',
            flexDirection: 'row',
            justifyContent: 'center',
            borderRadius: 30
        },

        //TEXT

        textSmall: {
            fontFamily: utils.font,
            fontSize: utils.fontSizeSmall,
            // fontSize:14,
            color: utils.colors.smallText,
        },
        textNormal: {
            fontFamily: utils.font,
            fontSize: utils.fontSizeNormal,
            color: utils.colors.normalText,
        },
        textBig: {
            fontFamily: utils.fontBig,
            fontSize: utils.fontSizeBig,
            color: utils.colors.bigText,
        },
        textGiant: {
            fontFamily: utils.font,
            fontSize: utils.fontSizeGiant,
            // fontWeight: '500',
            color: utils.colors.bigText,
        },
        textCenter: {
            textAlign: 'center',
        },
        textGreen: {
            color: utils.colors.good
        },
        textRed: {
            color: utils.colors.important
        },
        textBlue: {
            color: utils.colors.link
        },

        textLine: {
            height: utils.height,
            lineHeight: utils.height,
        },
        iconLine: {
            height: utils.height,
        },
        textLineBig: {
            height: utils.height * 1.5,
            lineHeight: utils.height * 1.5,
        },

        //TODO filter

        filterContainer: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingLeft: utils.padding,
            paddingRight: utils.padding,
        },
        filterContainerText: {
            lineHeight: utils.height
        },

        //TODO picker

        picker: {
            backgroundColor: 'white',
            height: utils.height,
            transform: [
                // { scaleY: 0.83 * getRatio()},
                // { scaleX: 0.83 * getRatio()},
            ]
        },
        pickerItem: {},

        //TODO selector

        selector: {
            backgroundColor: utils.colors.rowBackground,
            borderRadius: utils.borderRadius,
        },

        //TODO timer

        timerContainer: {
            padding: 0
        },
        timerRow: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            height: utils.height,
            paddingLeft: utils.padding,
            paddingRight: utils.padding,
        },
        timerRowBig: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: utils.padding,

            // backgroundColor: utils.colors.header
            borderWidth: 1,
            borderColor: utils.colors.inputBorder,
            overflow: 'hidden',
            borderRadius: utils.borderRadius,

            marginLeft: utils.padding,
            marginRight: utils.padding,


            // borderLeftColor: 'transparent',
            // borderRightColor: 'transparent',
        },
        timerValue: {
            lineHeight: utils.height,
        },


        //ROW with padding and background

        row: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingLeft: utils.padding,
            paddingRight: utils.padding,
            minHeight: utils.height,
            backgroundColor: utils.colors.rowBackground
        },
        rowBig: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: utils.padding,
            minHeight: utils.height * 1.5,
            backgroundColor: utils.colors.rowBackground
        },
        rowContainer: {
            flexDirection: 'row',
            minHeight: utils.height,
            lineHeight: utils.height,
        },
        rowInfo: {
            flexDirection: 'row',
            minHeight: utils.height,
            lineHeight: utils.height,
            textAlign: 'center',
            color: utils.colors.buttonText,
            backgroundColor: utils.colors.important
        },
        rowBigSchedule: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingLeft: utils.padding,
            paddingRight: utils.padding,
            minHeight: utils.height * 1.5,
            backgroundColor: utils.colors.rowBackground
        },


        unfoldIcon: {
            paddingRight: utils.padding
        },

        //TODO

        rowYellow: {
            backgroundColor: utils.colors.importantRow
        },

        rowImportant: {
            backgroundColor: utils.colors.tab
        },


        value: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            backgroundColor: utils.colors.rowBackground,

            borderWidth: 1,
            borderColor: 'transparent',
            borderBottomColor: '#dadada',
        },

        infoBlock: {
            alignItems: 'center',
            padding: utils.padding * (2.5 / getRatio())
        },

        timerLine: {
            backgroundColor: '#171717'
        },

        timerLineText: {
            color: '#ffffff',
        },

        separator: {
            height: utils.padding
        },
        line: {
            height: 1,
            backgroundColor: palette.gray,
            // marginBottom: utils.padding,
            // marginTop: utils.padding,
            // margin: utils.padding,
            // paddingLeft: utils.padding,


        },


        back: {
            flexDirection: 'row',
            backgroundColor: utils.colors.backColor,
            paddingLeft: utils.padding
        },
        backIcon: {
            color: utils.colors.menuColor
        },
        backText: {
            padding: utils.padding
        },

        //TODO

        center: {
            textAlign: 'center',
            alignItems: 'center'
        },
        indent: {
            paddingBottom: utils.padding / 2
        },

        //CALENDAR

        calendar: {
            borderTopRightRadius: utils.borderRadius,
            borderTopLeftRadius: utils.borderRadius,
        },
        calendarHeader: {
            backgroundColor: utils.colors.header
        },
        timePickerContainer: {
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-around',
            // alignItems: 'center',
        },
        timePicker: {
            // width: 100,
            flex: 1,
            // flexDirection: 'row',
            // justifyContent: 'space-around',
            backgroundColor: utils.colors.header,
            // width: '50%'
        },
        timePickerText: {

            // backgroundColor: utils.colors.header,
            // height: 100,
            // lineHeight: 100
        },
        timePickerBarrel: {
            backgroundColor: 'red',
        },

        //TODO
        loadingRow: {
            width: '0%',
            height: 2,
            backgroundColor: 'black'
        }
    });

    //LOGIN VIEW

    stylesLogin = StyleSheet.create({

        container: {
            flex: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: utils.padding * 2,
            backgroundColor: utils.colors.background
        },
        headerContainer: {
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            // alignItems: 'stretch',
        },
        headerButtonsContainer: {
            flexDirection: 'row',
            // height: 30
            // backgroundColor: 'red'
        },
        logoContainer: {
            // width: '50%'
            // paddingTop: utils.padding * 2,
            // overflow: 'hidden',
            // borderRadius: utils.borderRadius,
            // padding: utils.padding,
        },
        logo: {
            // width: 100
        },
        formContainer: {
            width: '100%',
        },
        messageContainer: {
            padding: utils.padding,
            paddingTop: 0
        }
    });

    //MAIN VIEW

    stylesMain = StyleSheet.create({

        container: {
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'space-between',
            // backgroundColor: 'ghostwhite',
        },
    });

    //HEADER

    stylesHeader = StyleSheet.create({

        container: {
            // zIndex: 0,
            // flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
            height: utils.height,
            backgroundColor: utils.colors.header,
            // paddingTop: Constants.statusBarHeight,
            // backgroundColor: 'red'
        },
        balanceContainer: {
            // flex: 1,
            flexDirection: 'row',
            paddingLeft: utils.padding / 2,
            paddingRight: utils.padding / 2,
            margin: utils.padding / 2,
            marginLeft: utils.padding,
            marginRight: utils.padding,
            // justifyContent: 'flex-start',
            // height: utils.height,
            borderRadius: utils.borderRadius,
            backgroundColor: 'white',

        },
        balanceText: {
            lineHeight: utils.height / 1.5,
        },
        separator: {
            width: 2,
            height: '100%',
            backgroundColor: utils.colors.background,
            marginLeft: utils.padding / 2,
            marginRight: utils.padding / 2,
        },

        statusContainer: {
            flexDirection: 'column',
            justifyContent: 'space-around',

        },
        statusText: {
            color: palette.black,

            height: utils.height / 2,
            lineHeight: utils.height / 2,

            paddingLeft: utils.padding / 2,
            paddingRight: utils.padding / 2,

            borderRadius: utils.borderRadius,
            overflow: 'hidden',
            backgroundColor: palette.white,

            borderWidth: 1,
            borderColor: palette.black,
        },

        profileIcon: {
            marginLeft: utils.padding,
            marginRight: utils.padding,
            backgroundColor: 'white',
            borderRadius: utils.borderRadius,
            overflow: 'hidden'
            // color: utils.colors.menuColor
        },
        profileIconActive: {
            marginLeft: utils.padding,
            marginRight: utils.padding,
            backgroundColor: 'black',
            borderRadius: utils.borderRadius,
            overflow: 'hidden'
        }
    });

    //BOTTOM MENU

    stylesMenu = StyleSheet.create({
        container: {
            flexDirection: 'row',
            justifyContent: 'space-around',
            height: utils.height * 1.2,
            backgroundColor: utils.colors.menuColor,
        },
        icon: {
            marginLeft: utils.padding,
            marginRight: utils.padding / 2,
        }
    });

    //UPPER TAB COMPONENT

    stylesTab = StyleSheet.create({

        tabContainer: {
            // flex: 1,
            // alignContent: 'stretch',
            // alignItems: 'stretch',
            flexDirection: 'row',
            backgroundColor: utils.colors.tab,
            justifyContent: 'space-around',
        },
        tabItemContainer: {
            // flex: 1,
            // width: '100%',

            alignSelf: 'stretch',
            borderWidth: 2,
            borderColor: utils.colors.tab,

            // backgroundColor: 'red',

            // borderBottomColor: '#000000'
            paddingLeft: utils.padding,
            paddingRight: utils.padding,
        },
        tabItemContainerActive: {
            borderBottomColor: utils.colors.tabBorder,
        },
        tabItemText: {
            // flex: 1,
            textAlign: 'center',
            height: utils.height,
            lineHeight: utils.height,
        },
    });

    // PAPER ITEM

    stylesCard = StyleSheet.create({

        container: {

            padding: utils.padding,
            margin: utils.padding,

            marginTop: 0,

            borderRadius: utils.borderRadius,

            backgroundColor: '#ffffff',

            borderWidth: 1,
            borderColor: 'white',
        },

        containerInfo: {

            padding: utils.padding,
            margin: utils.padding / 2,

            marginTop: 0,

            borderRadius: utils.borderRadius,

            backgroundColor: '#ffffff',

            borderWidth: 1,
            borderColor: 'white',
        },

        itemRowContainer: {
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        itemHeaderContainer: {
            paddingBottom: utils.padding / 2
        },

        selected: {
            borderWidth: 1,
            borderColor: 'black',
        }

    });

    stylesPhoto = StyleSheet.create({
        container: {

            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,

            // alignSelf: 'flex-end',
            // position: 'absolute',
            // zIndex: 2,
            // top: 0, left: 0, right: 0, bottom: 0,
            // backgroundColor: utils.colors.background
            // flex: 1,
        },
        preview: {
            flex: 1,
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        buttons: {
            flexDirection: 'row',
            justifyContent: 'space-around',
            position: 'absolute',
            bottom: utils.height,
            left: 0,
            right: 0,
        },
        capture: {
            // flex: 0,
            backgroundColor: 'white',
            borderRadius: utils.iconSize / 2 + utils.padding,
            overflow: 'hidden',
            // padding: utils.padding,
            // paddingHorizontal: utils.padding,
            alignSelf: 'center',
            padding: utils.padding,
        },
    });
};

generateStyleSheet();
