import { api } from './api';
import { Platform } from 'react-native';
import { storeData } from '../screens/loginScreen';
import { getDate } from "./time";

let isAndroid = Platform.OS === 'android'
export let driverSession = {};


export const checkUpdate = async function () {

    let url = api.updateUrl + (isAndroid ? 'android' : 'ios') + '/version.json?m=' + getDate().getTime();

    return get(url).then((responseJson) => {
        return responseJson;
    });
}

let postHTML = async function (url, params) {

    return fetch(url, {

        method: 'POST',
        headers: {
            'Accept': '*/*',
            'Content-Type': 'application/json'
        },
        body: params
    })
        .then((responseJson) => {
            return responseJson
        })
        .catch((error) => {

            console.log(error)
        });
};



let post = async function (url, params) {

    return fetch(url, {

        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-Session-Id': driverSession.id,
        },
        body: JSON.stringify(params)
    })
        .then((response) => {
            return response.json()
        })
        .then((responseJson) => {

            return responseJson;
        })
        .catch((error) => {

            console.log('error',error)
        });
};

let get = async function (url) {

    return fetch(url, {

        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-Session-Id': driverSession.id
        },
    })
        .then((response) => {
            return response.json()
        })
        .then((responseJson) => {

            // console.log(responseJson)

            return responseJson;
        })
        .catch((error) => {

            console.log(error)
        });
};

//LOGIN

export let login = async function (phone, password) {

    driverSession = {};

    let user_agent = '3.1.9 web';

    return post(api.URLSessionsInit, {
        'phone': phone,
        'password': password,
        'user_agent': user_agent
    })

        .then((responseJson) => {

            // console.log(responseJson)

            if (responseJson) {
                driverSession = responseJson;
                storeData('driverSession', JSON.stringify(driverSession)).then();
            }

            return responseJson;

        }).catch((error) => {

            console.log(error)
        });
    ;
};

export let logout = async function () {

    let session_id = driverSession.id;
    let device_id = driverSession.fcmToken;

    return fetch(api.URLSessionsClose(session_id, device_id), {

        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'X-Session-Id': driverSession.id
        },
    })
        .then((response) => response.json())
        .then((responseJson) => {

            // console.log(responseJson);

            driverSession = {};
            storeData('driverSession', '').then();

            return responseJson;
        })
        .catch((error) => {
            // console.error(error);
        });
};

export let passwordRestore = async function (phone) {
    return post(api.URLDriverPasswordRestore, { 'phone': phone })
        .then((responseJson) => {
            return responseJson;
        });
};

//ORDER STATES

export let ordersList = async function () {
    return get(api.URLOrdersList)
        .then((responseJson) => {
            return responseJson.orders;
        });
};

export let ordersNew = async function () {
    return get(api.URLOrdersNew)
        .then((responseJson) => {
            return responseJson.orders;
        });
};

export let ordersListShort = async function () {
    return get(api.URLOrdersListShort)
        .then((responseJson) => {
            return responseJson.orders;
        });
};
export let ordersListShortLong = async function () {
    return get(api.URLOrdersListShortLong)
        .then((responseJson) => {
            return responseJson.orders;
        });
};

export let ordersNewShort = async function () {
    return get(api.URLOrdersNewShort)
        .then((responseJson) => {
            return responseJson.orders;
        });
};

export let loadingPoint = async function (order_id) {
    return post(api.URLOrderLoadingPoint(order_id), {})
        .then((responseJson) => {
            return responseJson;
        });
};

export let closeOrder = async function (order_id, total) {
    return post(api.URLOrderClose(order_id), {
        total: total
    })
        .then((responseJson) => {
            return responseJson;
        });
};

export let ordersGet = async function (order_id) {
    return get(api.URLOrdersGetData(order_id))
        .then((responseJson) => {
            return responseJson;
        });

};


//
// export let NewsShort = async function () {
//     return get(api.URLNews)
//         .then((responseJson) => {
//             return responseJson.news
//         });
// };
// export let NewsGet = async function (id) {
//     return get(api.URLNewsGetData(id))
//     .then((responseJson) => {
//         return responseJson
//     });

// };


//
export let setFreeAfterTime = async function (freeAfter) {
    return post(api.URLFreeAfterSetTime,
        {
            free_after: freeAfter,
            // sector_id: sectorId
        })
        .then((responseJson) => {
            return responseJson;
        });
};

export let wayBillsList = async function (order_id) {
    return get(api.URLWayBillsList(order_id))
        .then((responseJson) => {
            return responseJson.waybills;
        });
}

export let preCalculate = async function (order_id, secondsBeforeEnd) {
    return get(api.URLOrderPreCalculate(order_id, secondsBeforeEnd))
        .then((responseJson) => {
            return responseJson;
        });
};


//DRIVER

export let driverGetData = async function () {
    return get(api.URLDriverGetData)
        .then((responseJson) => {
            return responseJson;
        });
};

export let notificationsList = async function () {
    return get(api.URLNotifications)
        .then((responseJson) => {

            // {code: 1007, message: "Не удалось найти колонну водителя"}
            if (responseJson.code === 1007)
                return [];

            return responseJson.notifications;
        });
};
export let newsList = async function () {
    return get(api.URLNews)
        .then((responseJson) => {

            // {code: 1007, message: "Не удалось найти колонну водителя"}
            if (responseJson.code === 1007)
                return [];

            return responseJson.news;
        });
};
export let balanceList = async function () {
    return get(api.URLFundsFlow)
        .then((responseJson) => {
            return responseJson;
        });
};

export let scheduleList = async function () {
    return get(api.URLDriverSchedule)
        .then((responseJson) => {
            return responseJson.schedule;
        });
};

export let terminalsList = async function () {
    return get(api.URLTerminals)
        .then((responseJson) => {

            if (responseJson.code === 1008)
                return [];

            return responseJson.terminals;
        });
};

export let fuelCard = async function () {
    return get(api.URLFuelCardStatus)
        .then((responseJson) => {
            return responseJson;
        });
};

export let fuelCardTransfer = async function (cardId, sum) {
    return post(api.URLFuelCardTransfer, {
        card_id: cardId,
        sum: sum
    })
        .then((responseJson) => {
            return responseJson;
        });
};

export let feedbacksList = async function () {
    return get(api.URLFeedbackList)
        .then((responseJson) => {
            return responseJson.feedback;
        });
};

export let feedbacksGet = async function (id) {
    return get(api.URLFeedbackGetData + id)
        .then((responseJson) => {
            return responseJson;
        });
};

export let serverTime = async function (id) {
    return get(api.URLServerTime)
        .then((responseJson) => {
            return responseJson;
        });
};

export let feedbackCreate = async function (related_order_number, order_asking_price, messageDay, description, type_id) {
    return post(api.URLFeedbackCreate,
        {
            related_order_number: related_order_number,
            related_user: '',
            order_asking_price: order_asking_price,
            issue_timestamp: messageDay,
            description: description,
            type_id: type_id,
        })
        .then((responseJson) => {
            return responseJson;
        });
};

export let feedbackStates = async function () {
    return get(api.URLFeedbackStates)
        .then((responseJson) => {
            return responseJson;
        });
};

export let feedbackTypes = async function () {
    return get(api.URLFeedbackTypes)
        .then((responseJson) => {
            return responseJson.types;
        });
};

export let feedbackUpdate = async function (id, accept_result, driver_deny_reason, rating, driver_comment) {
    return post(api.URLFeedbackUpdate(id),
        {
            accept_result: accept_result,
            driver_deny_reason: driver_deny_reason,
            driver_comment: driver_comment,
            rating: rating,
        })
        .then((responseJson) => {
            return responseJson;
        });
};

export let coordinates = async function (lat, lng, timestamp, gps_tracker_id) {

    // console.log('coordinates')

    // console.log(lat)
    // console.log(lng)
    // console.log(timestamp)
    // console.log(gps_tracker_id)

    // if (globalDriver.is_our) {
    //     console.log('WRONG DRIVER')
    //     return null;
    // }

    return post(api.URLCoordinates, {
        location: {
            lat: lat,
            lng: lng,
            timestamp: timestamp,
            gps_tracker_id: gps_tracker_id
        }
    })
        .then((responseJson) => {
            return responseJson;
        });
};

//TODO
function getPlatform() {
    let platform = Platform.OS;
    return platform === 'android' ? 'google' : 'apple-fb';
}

export let deviceRegister = async function (device_id) {
    return post(api.URLDevicesRegister,
        {
            endpoint_type: getPlatform(),
            device_id: device_id
        })
        .then((responseJson) => {
            return responseJson;
        });
};

export let deviceList = async function () {
    return get(api.URLDevicesList)
        .then((responseJson) => {
            return responseJson;
        });
};

export let inspectionsList = async function (photos, id) {
    return get(api.URLInspectionsList)
        .then((responseJson) => {
            return responseJson.inspections;
        });
}


export let handCanCreate = async function () {
    return get(api.URLHandCanCreate)
        .then((responseJson) => {
            return responseJson;
        });
};

export let handTariff = async function () {
    return get(api.URLHandTariff)
        .then((responseJson) => {
            return responseJson;
        });
};

export let handCreate = async function () {
    return post(api.URLHandCreate)
        .then((responseJson) => {
            return responseJson;
        });
};

export let handClose = async function (order_id, total) {
    return post(api.URLHandClose(order_id), {
        total: total
    })
        .then((responseJson) => {
            return responseJson;
        });
};


// export let breakDownCreate = async function (id, photos) {
//     return post(api.URLBreakDownCreate,
//         {
//             id,
//             files: photos,
//             driver_comment: driver_comment,
//         })
//         .then((responseJson) => {
//             return responseJson
//         })
// }


export let loadersCard = async function () {
    return get(api.URLLoaderStatus)
        .then((responseJson) => {
            return responseJson;
        });
};

export let loadersCardTransfer = async function (cardId, sum) {
    return post(api.URLLoadersTransfer, {
        card_id: cardId,
        sum: sum
    })
        .then((responseJson) => {
            return responseJson;
        });
};

export let dayIncomeBalance = async function () {
    return get(api.URLDayIncome)
        .then((responseJson) => {
            return responseJson;
        })
};

export let planning = async function () {
    return get(api.URLPlanning)
        .then((responseJson) => {
            return responseJson;
        });
};


export let surcharge = async function (from, to) {
    return get(api.URLSurcharge(from, to))
        .then((responseJson) => {
            return responseJson
        });
};

export let noCargoPhotosOrders = async function () {
    return get(api.URLCargoPhotosOrders)
        .then((responseJson) => {
            return responseJson;
        });
};

export let replenishDeposit = function (driver_id, summ) {
        return postHTML(api.URLDeposit(driver_id, summ))
            .then((responseJson) => {
                return responseJson;
            });
}
export let replenishDepositAlternative = function (driver_id, summ) {
    return postHTML(api.URLDepositAlternative(driver_id, summ))
        .then((responseJson) => {
            return responseJson;
        });
}

export let getRedemptionOrders = async function () {
    return get(api.URLRedempionOrders)
        .then((responseJson) => {
            return responseJson;
        });
};
export let buyRedemptionOrders = async function (order_id) {
    return get(api.URLBuyRedempionOrders(order_id))
        .then((responseJson) => {
            return responseJson;
        });
};