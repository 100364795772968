export let serverTimeGlobal = 0;
export let offset = 0;
let timeZoneOffset = -180 - new Date().getTimezoneOffset()
export const monthNames = ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря'];

// let serverOffset =  - (180 * 60);

export let setOffset = function (serverTime) {

    // console.log(serverTime)

    if (!serverTime)
        return;

    serverTimeGlobal = serverTime;

    // console.log(new Date(DeviceInfo.getLastUpdateTime()))

    // console.warn(new Date())
    // console.warn(new Date(serverTime * 1000))

    offset = Math.floor(new Date(serverTime * 1000).getTime() / 1000 - new Date().getTime() / 1000);

    // let offsetInSec = new Date().getTimezoneOffset() * 60;

    // console.log(offsetInSec)

    // offset = Math.floor(offsetInSec - serverOffset);


    // let offsetInSec = new Date(serverTime * 1000).getTimezoneOffset() * 60;
    // console.log(offsetInSec)

    // let serverOffsetInSec = new Date(serverTimeGlobal * 1000).getTimezoneOffset() * 60;

    console.warn(offset)
}

export let getDate = function () {
    return new Date((new Date() / 1000 + offset) * 1000);
}


// 8 Мая в 12:00
export function getDateString(time) {

    if (!time || time === 0)
        return '';

    let timeObject = new Date(time * 1000 - timeZoneOffset * 60 * 1000);
    // timeObject.toLocaleString("ru-RU", {timeZone: "Europe Moscow"})

    return timeObject.getDate() + ' ' + monthNames[timeObject.getMonth()] + ' в ' + timeObject.getHours() + ':' +
        (timeObject.getMinutes().toString().length === 1 ? '0' + timeObject.getMinutes() : timeObject.getMinutes());
}

// 15:57
export function getOnlyTimeString(time) {

    if (time === 0)
        return 'Время';

    let timeObject = new Date(time * 1000 - timeZoneOffset * 60 * 1000);
    // timeObject.toLocaleString("ru-RU", {timeZone: "Europe Moscow"})

    return timeObject.getHours() + ':' +
        (timeObject.getMinutes().toString().length === 1 ? '0' + timeObject.getMinutes() : timeObject.getMinutes());
}

// 9 Мая
export function getOnlyDateString(time) {

    if (time === 0)
        return 'Время';

    let timeObject = new Date(time * 1000 - timeZoneOffset * 60 * 1000);
    // timeObject.toLocaleString("ru-RU", {timeZone: "Europe Moscow"})

    return timeObject.getDate() + ' ' + monthNames[timeObject.getMonth()];
}

export function getTimerString(sec) {

    // console.log(sec)
    // console.log(Math.floor(getDate().getDate()/1000 - sec))

    if (!sec || sec < 0)
        sec = 0;

    sec = Math.floor(sec)

    let hours = Math.floor(sec / (60 * 60));
    let minutes = Math.floor(sec / 60) % 60;
    let seconds = Math.floor(sec % 60);

    hours = (hours.toString().length === 1 ? '0' + hours : hours);
    minutes = (minutes.toString().length === 1 ? '0' + minutes : minutes);
    seconds = (seconds.toString().length === 1 ? '0' + seconds : seconds);

    return hours + ':' + minutes + ':' + seconds;
}

export function getTimerStringSmall(sec) {

    // console.log(sec)
    // console.log(Math.floor(getDate().getDate()/1000 - sec))

    if (!sec || sec < 0)
        sec = 0;

    sec = Math.floor(sec)

    // let hours = Math.floor(sec / (60 * 60));
    let minutes = Math.floor(sec / 60) % 60;
    let seconds = Math.floor(sec % 60);

    // hours = (hours.toString().length === 1 ? '0' + hours : hours);
    minutes = (minutes.toString().length === 1 ? '0' + minutes : minutes);
    seconds = (seconds.toString().length === 1 ? '0' + seconds : seconds);

    return minutes + ':' + seconds;
}

export function getMoneyString(count) {

    // console.log('Money: ' + count)

    count = (count + '').replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    // console.log('Money: ' + count)

    return count;
}
