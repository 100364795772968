const main = {
    colors: {
      white: '#ffffff',
      black: '#000000',
      gray: '#a3a3a3',
      grayLight: '#f8f8f8',
      textGray: '#868585',
      background: '#e5e5e5',
      grayLightIOS: '#efeff4',
      blueLight: '#8b61ff',
    },
  }
  export default main;
  
  