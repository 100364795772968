import { driverSession } from "./network";

import Echo from 'laravel-echo'
import { api } from './api'
export let echo = '';

export function setOrderSocket(id, updateOrder) {
    if (window.Echo)
            window.Echo.private(`order.${id}`).listen('OrderCounterUpdated', event => {
                updateOrder(event)
            })
}

export function initWebSocket() {

    window.Echo = new Echo({
        broadcaster: 'socket.io',
        host: api.WS, //whatever url you need
        secure: true,
        transports: ['websocket', 'polling', 'flashsocket'],
        auth: {
            headers: 
            {
                'X-Session-Id': driverSession.id,
            }
        }
    });

    window.Echo.connector.socket.on('connect', function(){
        // this.isConnected = true
        console.log('connect')

    })

    window.Echo.connector.socket.on('disconnect', function(){
        // this.isConnected = false
        console.log('disconnect')
    })

   
}
