import firebase from 'firebase';
import 'firebase/messaging';
import { deviceRegister } from './network';

export const initializeFirebase = () => {
  firebase.initializeApp({
    apiKey: "AIzaSyDphibODTsZqZXb8o9s_hhZD2C0gHiEcNY",
    authDomain: "gazelkin-drivers-29225.firebaseapp.com",
    databaseURL: "https://gazelkin-drivers-29225.firebaseio.com",
    projectId: "gazelkin-drivers-29225",
    storageBucket: "gazelkin-drivers-29225.appspot.com",
    messagingSenderId: "171802467582",
    appId: "1:171802467582:web:d2ecd00a2998a7056899af",
    measurementId: "G-NS74X8DPMJ" // troque pelo seu sender id 
  });
}

export const askForPermissioToReceiveNotifications = async () => {

  try {
  
    const messaging = firebase.messaging();

    await messaging.requestPermission();
   
    const token = await messaging.getToken();
    // console.log('user token: ', token);
    // console.log(messaging)
    deviceRegister(token).then((response) =>{
      // console.log(response)
    })
   

    return token;
  } catch (error) {
    console.error(error);
  }
}