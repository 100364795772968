import { useEffect, useState } from "react";
import { Text, View } from "react-native"
import { balanceList } from "../services/network";
import { getMoneyString, palette, utils } from "../styles/utils";

const Header = () => {
    const [balance, setBalance] = useState([])
    useEffect(() => {
        balanceList().then(response => {
            setBalance(response)
        })
    },[])
    return (
        <View style={{borderBottomWidth: 0.5, paddingHorizontal: utils.padding, marginTop: -utils.padding * 2,flexDirection: 'row', justifyContent: 'flex-end'}}>
            <View style={{justifyContent: 'center'}}>
                    <Text style={{fontSize: 18, paddingBottom: utils.padding / 2}}>
                                {'Баланс:'} <Text style={{color: (balance.balance > 0 ? palette.blue : palette.red)}}>{`${getMoneyString(balance.balance)} ₽`}</Text>
                            </Text>
            </View>
    </View>

    )
}
export default Header;