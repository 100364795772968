import * as React from "react"
import Svg, { Path } from "react-native-svg"

function BuyOrder(props) {
  return (
    <Svg 
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512.16 512.16"
    {...props}>
      <Path fill={props.focused === 'true' ? '#fdd107' : 'black'} d="M157.662 102.614a8.017 8.017 0 00-8.017 8.017c0 9.725-7.912 17.637-17.637 17.637s-17.637-7.912-17.637-17.637 7.912-17.637 17.637-17.637a8.017 8.017 0 000-16.034c-18.566 0-33.67 15.105-33.67 33.67s15.105 33.67 33.67 33.67 33.67-15.105 33.67-33.67a8.016 8.016 0 00-8.016-8.016zM157.662 196.676a8.017 8.017 0 00-8.017 8.017c0 9.725-7.912 17.637-17.637 17.637s-17.637-7.912-17.637-17.637 7.912-17.637 17.637-17.637a8.017 8.017 0 000-16.034c-18.566 0-33.67 15.105-33.67 33.67s15.105 33.67 33.67 33.67 33.67-15.105 33.67-33.67a8.016 8.016 0 00-8.016-8.016zM251.724 213.779h-59.858a8.017 8.017 0 000 16.034h59.858a8.017 8.017 0 000-16.034zM251.724 179.574h-59.858a8.017 8.017 0 000 16.034h59.858a8.017 8.017 0 000-16.034zM234.622 307.841h-42.756a8.017 8.017 0 000 16.034h42.756a8.017 8.017 0 000-16.034zM251.724 273.637h-59.858a8.017 8.017 0 000 16.034h59.858a8.017 8.017 0 000-16.034zM328.685 119.716H191.866a8.017 8.017 0 000 16.034h136.818a8.017 8.017 0 00.001-16.034zM294.48 85.511H191.866a8.017 8.017 0 000 16.034H294.48a8.017 8.017 0 000-16.034zM157.662 290.739a8.017 8.017 0 00-8.017 8.017c0 9.725-7.912 17.637-17.637 17.637s-17.637-7.912-17.637-17.637 7.912-17.637 17.637-17.637a8.017 8.017 0 000-16.034c-18.566 0-33.67 15.105-33.67 33.67s15.105 33.67 33.67 33.67 33.67-15.105 33.67-33.67a8.016 8.016 0 00-8.016-8.016z" />
      <Path fill={props.focused === 'true' ? '#fdd107' : 'black'} d="M362.889 0H72.15C58.3 0 47.031 11.268 47.031 25.119v359.148c0 13.851 11.268 25.119 25.119 25.119h145.37a8.017 8.017 0 000-16.034H72.15c-5.01 0-9.086-4.076-9.086-9.086V25.119c0-5.01 4.076-9.086 9.086-9.086h290.739c5.01 0 9.086 4.076 9.086 9.086v265.087a8.017 8.017 0 0016.034 0V25.119C388.008 11.268 376.74 0 362.889 0z" />
      <Path fill={props.focused === 'true' ? '#fdd107' : 'black'} d="M438.578 325.094c-7.451-.743-14.898 1.369-20.792 5.844-4.695-7.878-12.701-13.467-21.964-14.395-7.453-.742-14.899 1.37-20.792 5.844-4.695-7.878-12.701-13.467-21.964-14.395a29.618 29.618 0 00-16.365 3.069V208.969a29.45 29.45 0 00-9.677-21.8c-6.145-5.56-14.426-8.274-22.721-7.444-14.799 1.482-26.391 14.863-26.391 30.464v102.35l-23.566 23.566c-12.523 12.523-17.578 30.291-13.521 47.531l17.891 76.037c7.249 30.811 34.418 52.329 66.07 52.329h72.307c37.426 0 67.875-30.448 67.875-67.875V355.56c.001-15.603-11.591-28.984-26.39-30.466zm10.357 119.031c0 28.585-23.256 51.841-51.841 51.841h-72.307c-24.175 0-44.927-16.435-50.464-39.968l-17.891-76.037c-2.776-11.795.683-23.953 9.251-32.521l12.229-12.229v27.678a8.017 8.017 0 0016.034 0V210.188c0-7.465 5.251-13.839 11.956-14.509 3.851-.387 7.534.815 10.366 3.379a13.393 13.393 0 014.401 9.912v141.094a8.017 8.017 0 0016.034 0v-12.827c0-3.768 1.603-7.381 4.401-9.912 2.834-2.564 6.515-3.767 10.366-3.379 6.704.671 11.956 7.045 11.956 14.51v20.157a8.017 8.017 0 0016.034 0v-12.827c0-3.768 1.603-7.381 4.401-9.912 2.834-2.564 6.516-3.766 10.366-3.379 6.704.671 11.956 7.045 11.956 14.51v20.158a8.017 8.017 0 0016.034 0v-12.827c0-3.768 1.603-7.381 4.401-9.912 2.834-2.563 6.513-3.767 10.366-3.378 6.704.67 11.956 7.044 11.956 14.509v88.57z" />
    </Svg>
  )
}

export default BuyOrder;
