import * as React from 'react';
import { TouchableOpacity } from 'react-native';
import { ScrollView, RefreshControl,View, Text, Platform } from 'react-native';
import { ordersGet, ordersList, ordersNewShort } from '../../services/network';
import OrderCard from './orderCard';
import CurrentOrder from './order';
import { ActivityIndicator } from 'react-native';
import { askForPermissioToReceiveNotifications } from '../../services/firebase';
import { initWebSocket } from '../../services/socket';
import { palette } from '../../styles/utils';
import { useNavigation } from '@react-navigation/native';


const OrdersScreen = () => {
const [ orders, setOrders ] = React.useState([]);
const [ completedOrders, setCompletedOrders ] = React.useState([]);
const [ refreshing, setRefreshing ] = React.useState(false);
const [ orderTab, setOrderTab] = React.useState(1);
const [modalVisible, setModalVisible] = React.useState(false);
const [orderFull, setOrderFull] = React.useState(false);
const navigation = useNavigation();


const onRefresh = React.useCallback(() => {
  setRefreshing(true);
    setTimeout(() => {
      getOrders()
      setRefreshing(false)
    }, 3000);
}, []);

const setTab = (index) => {
  setOrderTab(index)
  if(index === 2) { 
        setRefreshing(true);
        getCompletedOrders();
      }
  if(index === 1) { 
    getOrders();
   }

}
const getCompletedOrders = () =>{
  return ordersList().then((responseJson) => {
    setTimeout(() => {
        setCompletedOrders(responseJson)
        setRefreshing(false);
      }, 3000);
    })
}

const getOrders = () =>{
  setRefreshing(true)
  return ordersNewShort().then((responseJson) => {
    setTimeout(() => {
      setOrders(responseJson)
        setRefreshing(false)
      }, 3000);
    })
}
React.useEffect(() => {
  getOrders();
  if(Platform.OS === 'android'){
    askForPermissioToReceiveNotifications();
  }
  initWebSocket();
}, []);

React.useLayoutEffect(() => {
  const list = navigation.addListener('focus', () => {
    getOrders();
  })
  return list;
}, [])

const getOrderForId = (order) =>{
  ordersGet(order.id).then((responseJson) => {
    setOrderFull(responseJson);
        setModalVisible(true)
  })
}

if(!orders.length && orderTab === 1){
  return (
    <View style={{ flex: 1,justifyContent: 'flex-start', backgroundColor:palette.white}}>
      <View style={{padding: 16, flexDirection:'row', justifyContent:'space-between'}}>
      <TouchableOpacity onPress={() => setTab(1)} style={{borderBottomWidth:( orderTab === 1 ? 1 : 0)}}>
         <Text style={{textAlign:'center', paddingBottom: 4}}>{'Текущие заказы'}</Text>
      </TouchableOpacity>
          <TouchableOpacity onPress={() => setTab(2)} style={{borderBottomWidth:( orderTab === 2 ? 1 : 0)}}>
                <Text style={{textAlign:'center', paddingBottom: 4}}>{'Завершенные заказы'}</Text>
          </TouchableOpacity>
      </View>
      <View style={{paddingVertical: 40, alignItems:'center'}}>
      {refreshing ?
      <ActivityIndicator size={'large'} color={'#fdd107'}
      /> :
          <Text>{'Нет заказов'}</Text>
      }
      </View>
    </View>
  );
}
if(!completedOrders.length && orderTab === 2){
  return (
    <View style={{ flex: 1,justifyContent: 'flex-start', backgroundColor:palette.white}}>
      <View style={{padding: 16, flexDirection:'row', justifyContent:'space-between'}}>
      <TouchableOpacity onPress={() => setTab(1)} style={{borderBottomWidth:( orderTab === 1 ? 1 : 0)}}>
         <Text style={{textAlign:'center', paddingBottom: 4}}>{'Текущие заказы'}</Text>
      </TouchableOpacity>
          <TouchableOpacity onPress={() => setTab(2)} style={{borderBottomWidth:( orderTab === 2 ? 1 : 0)}}>
                <Text style={{textAlign:'center', paddingBottom: 4}}>{'Завершенные заказы'}</Text>
          </TouchableOpacity>
      </View>

      <View style={{paddingVertical: 40, alignItems:'center'}}>
      {refreshing ?
      <ActivityIndicator size={'large'} color={'#fdd107'}
      /> :
          <Text>{'Нет заказов'}</Text>
      }
      </View>
    </View>
  );
}

    return (
      <View style={{ flex: 1,justifyContent: 'center', backgroundColor:'#fff'}}>
        <View style={{padding: 16, flexDirection:'row', justifyContent:'space-between'}}>
        <TouchableOpacity onPress={() => setTab(1)} style={{borderBottomWidth:( orderTab === 1 ? 1 : 0)}}>
           <Text style={{textAlign:'center', paddingBottom: 4}}>{'Текущие заказы'}</Text>
        </TouchableOpacity>
            <TouchableOpacity onPress={() => setTab(2)} style={{borderBottomWidth:( orderTab === 2 ? 1 : 0)}}>
                  <Text style={{textAlign:'center', paddingBottom: 4}}>{'Завершенные заказы'}</Text>
            </TouchableOpacity>
        </View>
        <ScrollView style={{flex: 1}} refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
          />} >
         {refreshing && <ActivityIndicator size={'large'} color={'#fdd107'}/>  }
          {orderTab === 1 && orders.length && orders.map((order, i) => {
              return (
                <View key={i}>
                    <TouchableOpacity key={order.id} onPress={() => getOrderForId(order)}>
                      <OrderCard order={order} key={order.id}/>
                  </TouchableOpacity>
                </View>
              )
          })}
            {orderTab === 2 && completedOrders.length && completedOrders.map((order, i) => {
             return (
                <View key={i}>
                  <TouchableOpacity key={order.id} onPress={() => getOrderForId(order)}>
                    <OrderCard order={order} key={order.id}/>
                </TouchableOpacity>
              </View>
            )
        })}
        </ScrollView>
             {modalVisible && 
                      <CurrentOrder visible={modalVisible} show={setModalVisible} order={orderFull}/>
                  }
      </View>
    );
  }

  export default OrdersScreen;
