export let api = {};

let cursor = 0;
let sortWay = 0;
export let maxCount = 30;

let terminals = 0;
let terminalsArray = [];

// export let test = 'rnd-12167.crm-dev';
export let test = 'test.crm-dev';

api.prod = true;

api.initURL = function (branch) {

    api.updateUrl = 'https://app.gzlk.in/'
    if (branch && branch.length === 4)
        if (branch && branch.length === 4) // delete !
            test = 'rnd-' + branch + '.crm-dev'
    if (branch && branch.length === 5) // delete !
        test = 'rnd-' + branch + '.crm-dev'
    //API
    // https://docs.crm-dev.gazelkin.local/api-drivers/
    // api.URL = 'http://api.gazdev.ru/api/app/drivers/v1'
    if (branch && branch.length === 4)
        test = 'rnd-' + branch + '.crm-dev'

    //API

    if (!api.prod)
        api.URL = 'https://' + test + '.gazelkin.local/api/app/drivers/v1';

    else
        api.URL = 'https://api.gazelkin.ru/app/drivers/v1';

    if (!api.prod)
        api.URLV2 = 'https://' + test + '.gazelkin.local/api/app/drivers/v2';

    else
        api.URLV2 = 'https://api.gazelkin.ru/app/drivers/v2';

    //WEBSOCKET
    // api.WS = api.prod ? 'https://api.gazelkin.ru:6001' : 'http://10.10.9.197:6004';
    api.WS = api.prod ? 'https://api.gazelkin.ru' : 'https://' + test + '.gazelkin.local';


    //REQUESTS

    api.URLSessionsInit = api.URL + '/sessions?'; //POST

    api.URLSessionsGetData = api.URL + '/sessions/:session_id?';
    api.URLSessionsList = api.URL + '/sessions?';
    api.URLSessionsClose = function (session_id, device_id) {
        return api.URL + '/sessions/' + session_id + '?&device_id=' + device_id;
    }


    api.URLDriverGetData = api.URL + '/driver?';
    api.URLDriverSchedule = api.URL + '/driver/schedule?';
    api.URLDriverPasswordRestore = api.URL + '/driver?';


    //
    // api.URLNews =  api.URL + '/news?' +'count='+10 +'&sort_way=' + 'DESC' + '&cursor=' + 5000
    // api.URLNewsGetData = function (id) {
    //     return api.URL + '/news/' //v2
    // }
    //

    api.URLOrdersList = api.URLV2 + '/orders?' + 'status=' + 'DONE' + '&count=' + 50;
    // '&cursor=' + cursor +
    // '&sort_way=' + sortWay +
    // '&count=' + maxCount;
    api.URLOrdersNew = api.URLV2 + '/orders?' + 'status=' + 'NEW' + '&count=' + 5;
    api.URLOrdersList = api.URLV2 + '/orders?' + '&status=' + 'DONE' + '&count=' + maxCount;
    // '&cursor=' + cursor +
    // '&sort_way=' + sortWay +
    // '&count=' + maxCount;
    api.URLOrdersNew = api.URLV2 + '/orders?' + '&status=' + 'NEW' + '&count=' + 5;
    // '&cursor=' + cursor +
    // '&sort_way=' + sortWay +
    // '&count=' + maxCount;

    api.URLOrdersListShort = api.URLV2 + '/orders?' + 'status=' + 'DONE' + '&count=' + 10 + '&context=format_short';
    api.URLOrdersListShortLong = api.URLV2 + '/orders?' + 'status=' + 'DONE' + '&count=' + 50 + '&context=format_short';
    api.URLOrdersNewShort = api.URLV2 + '/orders?' + 'status=' + 'NEW' + '&count=' + 5 + '&context=format_short';

    api.URLOrdersGetData = function (order_id) {
        return api.URLV2 + '/orders/' + order_id; //v2
    }

    //Возможность закрыть заказ
    api.URLOrderIsClose = api.URL + '/orders/:order_id/close?';

    //Заявка на закрытие заказа
    api.URLOrderClose = function (order_id) {
        return api.URLV2 + '/orders/' + order_id + '/close';
    }

    //Установка времени прибытия на точку погрузки
    api.URLOrderLoadingPoint = function (order_id) {
        return api.URL + '/orders/' + order_id + '/loading_point?';
    }

    //Установка статуса информирования водителя по заказу
    //Данный метод следует использовать только для заказов, у которых флаг driver_is_informed_by_order находится в состоянии false
    api.URLOrdersInformed = api.URL + '/orders/:order_id/informed?'; //POST

    api.URLOrderPreCalculate = function (order_id, secondsBeforeEnd) {
        return api.URLV2 + '/orders/' + order_id + '/pre_calculate?&seconds_before_end=' + secondsBeforeEnd;
    }


    api.URLInspectionsGetData = api.URL + '/inspections/:inspection_id?';
    api.URLInspectionsList = api.URL + '/inspections/'; //GET
    api.URLInspectionsUpdate = api.URL + '/inspections/'; // +id POST
    api.URLInspectionsCreate = api.URL + '/inspections/'; //POST

    api.URLNews = api.URL + '/news?' +
        '&cursor=' + cursor +
        '&sort_way=' + sortWay +
        '&count=' + maxCount;


    api.URLNotifications = api.URL + '/notifications?' +
        '&cursor=' + cursor +
        '&sort_way=' + sortWay +
        '&count=' + maxCount;


    api.URLNews = api.URL + '/news?' +
        '&cursor=' + cursor +
        '&sort_way=' + sortWay +
        '&count=' + maxCount;

    api.URLTerminals = api.URL + '/terminals?' +
        '&terminals=' + terminals +
        '&[]=' + terminalsArray;


    api.URLFundsFlow = api.URL + '/funds_flow?' +
        '&cursor=' + cursor +
        '&sort_way=' + sortWay +
        '&count=' + maxCount;


    api.URLWayBillsGetData = api.URL + '/orders/:order_id/waybills/:waybill_id?';
    api.URLWayBillsList = function (id) {
        return api.URL + '/orders/' + id + '/waybills';
    }

    api.URLWayBillsCreate = function (id) {
        return api.URL + '/orders/' + id + '/waybills';
    }

    api.URLWayBillsGetFile = api.URL + '/orders/:order_id/waybills/:waybill_id/files/:file_id?';


    api.URLFreeAfterIsAllowed = api.URLV2 + '/freeafter?';
    api.URLFreeAfterSetTime = api.URLV2 + '/freeafter?'; //POST

    api.URLCoordinates = api.URL + '/coordinates'; //POST


    api.URLFeedbackGetData = api.URL + '/feedback/';
    api.URLFeedbackList = api.URL + '/feedback?' +
        '&cursor=' + cursor +
        '&sort_way=' + sortWay +
        '&count=' + maxCount;
    api.URLFeedbackCreate = api.URL + '/feedback?';
    api.URLFeedbackUpdate = function (id) {
        return api.URL + '/feedback/' + id;
    }

    api.URLFeedbackStates = api.URL + '/feedback/states';
    api.URLFeedbackTypes = api.URL + '/feedback/types';

    api.URLFeedbackUploadPhoto = function (id) {
        return api.URL + '/feedback/' + id + '/attachment/photo';
    };

    api.URLFeedbackUploadAudio = function (id) {
        return api.URL + '/feedback/' + id + '/attachment/audio';
    };

    api.URLDevicesList = api.URL + '/devices';
    api.URLDevicesRegister = api.URL + '/devices';


    api.URLIncomingRequestCreate = api.URL + '/incoming_request' + //POST TODO
        '&key=';
    api.URLIncomingRequestTypesList = api.URL + '/incoming_request/types' + //TODO
        '&types=';


    api.URLFuelCardStatus = api.URL + '/fuel_card';
    api.URLFuelCardTransfer = api.URL + '/fuel_card/transfer';

    api.URLServerTime = api.URL + '/server_time'


    api.URLHandTariff = api.URLV2 + '/driver_orders/tariff'
    api.URLHandCanCreate = api.URLV2 + '/driver_orders/can_create_order'
    api.URLHandCreate = api.URLV2 + '/driver_orders/'
    api.URLHandClose = function (order_id) {
        return api.URLV2 + '/driver_orders/' + order_id + '/close';
    }

    // api.URLBreakDownCreate = api.URLV2 + '/breakdown/';
    api.URLBreakDownCreate = function () {
        return api.URLV2 + '/breakdown/';
    };
    api.URLLoaderStatus = api.URL + '/loaders_reward/';
    api.URLLoadersTransfer = api.URL + '/loaders_reward/transfer';
    api.URLDayIncome = api.URLV2 + '/day_income'
    api.URLPlanning = api.URLV2 + '/planning'
    // api.URLPlanningUpload = api.URLV2 + '/planning/upload'
    api.URLPlanningUpload = function () {
        return api.URLV2 + '/planning/upload';
    };


    api.URLSurcharge = function (from, to) {
        return api.URLV2 + '/surcharge?date_from=' + from + '&date_to=' + to
    }
    // api.URLBreakDownCreate = 'https://test.crm-dev.gazelkin.local/api/app/drivers/v2/breakdown/'

    api.URLCargoPhotosOrders = api.URL + '/orders/last5_no_cargo_photos'
    api.URLCargoPhotosUpload = function (order_id) {
        return api.URL + '/orders/' + order_id + '/cargo_photos'
    };

    api.URLDeposit = function(driver_id, summ) {
        return 'https://api.gazelkin.ru/payments/redirect/auto?payeeId=' + driver_id + '&payeeType=driver&amount=' + summ 
    };
    api.URLDepositAlternative = function(driver_id, summ) {
        return 'https://api.gazelkin.ru/payments/redirect/auto?payeeId=' + driver_id + '&payeeType=driver&amount=' + summ + '&use_alternative_gateway=true'
    };

    api.URLRedempionOrders = api.URLV2 + '/redemption_orders';
    api.URLBuyRedempionOrders = function (order_id) {
        return api.URLV2 + '/redemption_orders/' + order_id + '/redeem'
    };
}

api.initURL();

