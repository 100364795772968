import React, { useLayoutEffect } from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import OrdersScreen from '../screens/orders/currentOrders';
import BalanceScreen from '../screens/balance/balance';
import BalanceIcon from '../assets/icons/balance';
import OrdersIcon from '../assets/icons/orders';
import { palette, utils } from '../styles/utils';
import { View } from 'react-native';
import PoPup from '../components/PopUp';
import firebase from 'firebase';
import { Platform } from 'react-native';
import ProfileIcon from '../assets/icons/profile';
import BuyOrder from '../assets/icons/ordersBuy';
import HOME_ROUTES from './home_routes';
import ProfileScreen from '../screens/profile/profileScreen';
import { driverGetData, coordinates } from '../services/network';
import OutOrders from '../screens/orders/outsource';
import Header from '../components/header';

let activateTimer = function () { };

export function activateTimerEvent(event) {
  activateTimer = event;
}

let activateButtonArrival = function () { };

export function activateButtonArrivalEvent(event) {
  activateButtonArrival = event;
}

const Tabs = createBottomTabNavigator();
const HomeTabNavigator = ({ navigation, route }) => {

  const [ visiblePopUp, setVisiblePopUp ] = React.useState(false);
  const [ notification, setNotificationData ] = React.useState({});
  const [ driver, setDriverInfo ] = React.useState({});

if(Platform.OS ==='android') {
  const messaging = firebase.messaging();

  messaging.onMessage((payload) => {
    if(payload) {
      const notification = payload;
      setNotificationData(notification.data) 
          setTimeout(() => {
              setVisiblePopUp(true)
        }, 1000);
        let seen = [];
        JSON.stringify(notification.data, function (key, val) {
            if (val != null && typeof val == 'object') {
                if (seen.indexOf(val) >= 0) {
                    return;
                }
                seen.push(val);
            }
            return val;
        });

        // console.log(seen)

        let message;

        if (seen[0])
            message = seen[0];
        if (message && message.available) {

          let available = message.available;
          // console.log('available', available)
        
          if (available === '["order_counter"]') {
              activateTimer();
          }

          if(available === 'button_arrival_to_order')
          {
            activateButtonArrival();
          }
      }
    }
  
    return ;
})
}

  useLayoutEffect(() => {
    driverGetData().then(driver => {
      setDriverInfo(driver)
      if(driver.is_our){
        console.log('driver is our')
         return null;
        } else {
            setInterval(() => {
                window.navigator.geolocation.getCurrentPosition((position) => {
                    // console.log(position)
                    let lat = position.coords.latitude
                    let lon = position.coords.longitude
                    let timestamp = position.timestamp/1000
                    coordinates(lat, lon, timestamp, driver.gps_tracker_id).then();
                })
            }, 60000);
        }
    })

  },[navigation, route]);
  return (    
  <View style={{ flex: 1 }}>
  {visiblePopUp && 
    <View style={{flex: 1,position: 'absolute', zIndex: 1,width: '100%',height: 0,marginTop: 30,}}>
        <PoPup text= {notification.message}/>
    </View>
}
<View style={{marginTop: 50}}>
  <Header/>
</View>
    <Tabs.Navigator
    tabBarOptions={{
      activeTintColor: palette.darkyellow,
      style:{
        padding:14,
        borderTopColor:palette.gray,
        borderRadius:utils.padding,
      }
    }}>
      <Tabs.Screen
      name={HOME_ROUTES.Orders}
      component={OrdersScreen}
      options={{
        tabBarIcon:(({ focused }) => (
          <OrdersIcon focused={focused.toString()}/>
        ))
      }}
      />
      <Tabs.Screen
      name={HOME_ROUTES.Balance}
      component={BalanceScreen}
      options={{
        tabBarIcon:(({ focused }) => (
          <BalanceIcon focused={focused.toString()}/>
        ))
      }}
      />
  {driver.is_redemption_of_order_possible && 
    <Tabs.Screen
      name={HOME_ROUTES.OutOrders}
      component={OutOrders}
      options={{
        tabBarIcon:(({ focused }) => (
          <BuyOrder focused={focused.toString()}/>
        ))
      }}
      />
    }
    {/* <Tabs.Screen
      name={HOME_ROUTES.Profile}
      component={ProfileScreen}
      options={{
        tabBarIcon:(({ focused }) => (
          <ProfileIcon focused={focused.toString()}/>
        ))
      }}
      /> */}
  </Tabs.Navigator>
      </View>)
}

export default HomeTabNavigator;