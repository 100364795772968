import * as React from 'react';
import { View, Text, TextInput, TouchableOpacity } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { login, passwordRestore } from '../services/network'
import HomeTabNavigator from '../navigation/tabs';
import BackgroundLogin from '../assets/icons/background';
import PoPup from '../components/PopUp';
import { palette } from '../styles/utils';
import Rollbar from "rollbar";
export const rollbarManager = new Rollbar({
  accessToken: '525698eb4d654ff9b0ba6c02959099b0',
  captureUncaught: true,
  captureUnhandledRejections: true,
})
export let storeData = async (key, value) => {
    try {
        await AsyncStorage.setItem(key, value);
    } catch (error) {
        console.log(error)
    }
};

export let retrieveData = async (key) => {
    try {
        const value = await AsyncStorage.getItem(key);
        if (value !== null)
            return value;
    } catch (error) {
        console.log(error)
    }
};


const LoginScreen = () => {

    const [ number, setNumber] = React.useState('+7')
    const [ password, setPassword] = React.useState('')
    const [ alertMessage, setAlertMessage] = React.useState('')
    
    const [ errorsPassword, setErrorsPassword] = React.useState(false)
    const [ errorsNumber, setErrorsNumber] = React.useState(false)
    const [ loginSuccess, setLogin] = React.useState(false)
    const [ visiblePopUp, setVisiblePopUp] = React.useState(false)

    const setNumberValue = (number) =>{
        setNumber(number)
    }
    const setPasswordValue = (password) =>{
        setPassword(password)
    }

    const ResetPassword = () =>{
        if(number.length < 8){
             setErrorsNumber(true)
            } else {
                passwordRestore(number).then((responseJson) =>{
                    if(responseJson && responseJson.result === true){
                        setVisiblePopUp(true)
                        setAlertMessage(`Вам отправлен новый пароль на номер ${number}`)
                            setTimeout(() => {
                                setVisiblePopUp(false)
                            }, 3000);
                    }
                })
            }
    }
    const Auth = () => {
        login(number, password).then((responseJson) => {
            storeData('phone', number).then();
            storeData('password', password).then();
            // console.log(responseJson)
            if (!responseJson){
                setErrorsNumber(true)
                setErrorsPassword(true)
                }
            else 
            {
                if (responseJson.code === 1001) {
                    setErrorsNumber(true);
                    setErrorsPassword(true);
                }
                if (responseJson.code === 1002) {
                    setErrorsNumber(true);
                    setErrorsPassword(true);
                }
                if (responseJson.id){
                    storeData('driverSession', responseJson.id).then();
                    setLogin(true)
                }
            }
        })
    }

    React.useEffect(() =>{
        retrieveData('phone').then(value => {
            if(value){
                setNumberValue(value)
                retrieveData('password').then(value => {
                    if(value){
                        setPasswordValue(value)
                        retrieveData('driverSession').then(value => {
                            if(value){
                                // eslint-disable-next-line no-unused-expressions
                                Auth;
                            }
                        });
                    }
                })
             }
        })
    }, [])

    const clearError = () => {
        setErrorsPassword(false);
        setErrorsNumber(false);
    }

        if(loginSuccess) return (
            <View style={{position:'absolute', bottom: 14, height:'100%', width:'100%'}}>
                    <HomeTabNavigator/>
            </View>
        )
    return (
         <View style={{flex: 1, width:'100%', position:'absolute', bottom: 14,}}>
             {visiblePopUp && 
                    <View style={{ backgroundColor: 'red',flex: 1,position: 'absolute', zIndex: 1,width: '100%',height: 0,marginTop: -430,}}>
                        <PoPup text={alertMessage}/>
                    </View>
                }
                <View style={{ position:'absolute', alignSelf:'center', justifyContent:'center', marginVertical: -200}}>
                    <BackgroundLogin/>
                </View>
                                {/* {Config.ENV !== 'production' && */}
                                    {/* <TextInput style={{backgroundColor:'#ebebeb', margin: 14, padding: 8, borderRadius:8}} placeholder={'branch'} value={branch} onChangeText={(branch) => setBranchValue(branch)}/> */}
                                    {/* } */}
                                <TextInput maxLength={12} keyboardType={'phone-pad'} onFocus={() =>clearError()} style={{backgroundColor:'#ebebeb', margin: 14, padding: 8, borderRadius:8, borderColor: 'red', borderWidth: errorsNumber? 1 : 0}} value={number} onChangeText={(number) => setNumberValue(number)}/>
                                <TextInput maxLength={6} keyboardType={'numeric'} onFocus={() => clearError()} style={{backgroundColor:'#ebebeb', marginHorizontal: 14, padding: 8, borderRadius:8, borderColor: 'red', borderWidth: errorsPassword? 1 : 0}} placeholder={'Введите пароль'} value={password} onChangeText={(password) => setPasswordValue(password)}/>
                                <TouchableOpacity onPress={() => ResetPassword()} style={{backgroundColor:'#fdd107', margin: 14, borderRadius: 8, marginVertical:8}}>
                                    <Text style={{padding: 8, textAlign:'center'}}>
                                        {'Сбросить пароль'}
                                    </Text>
                                </TouchableOpacity>
                                <TouchableOpacity onPress={() => Auth()} style={{backgroundColor:'#fdd107', margin: 14, borderRadius: 14, marginTop:0}}>
                                    <Text style={{padding: 14, textAlign:'center'}}>
                                        {'Войти'}
                                    </Text>
                                </TouchableOpacity>
<Text style={{fontSize: 10, color: palette.gray, textAlign: 'center'}}>{'v.1.5'}</Text>
            </View>
    )
}

export default LoginScreen;